/* eslint-disable */

// This file needs to be broken up, checked for obsolete code and linted
import R_UTILS from './mc/ramda_utils'
import MCVideoPlayer from './mc/videoplayer'
import MCAudioPlayer from './mc/audioplayer'
import MCTimedTrackable from './mc/timedtrackable'
import MCAssetUploadButton from './mc/assetuploadbutton'
import videojs from 'video.js'

function getUrlParameter(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
	var results = regex.exec(location.search)
	return results === null
		? null
		: decodeURIComponent(results[1].replace(/\+/g, ' '))
}

$.extend($.ui.dialog.prototype.options, { closeText: '' })
window.R_UTILS = R_UTILS
window.MCVideoPlayer = MCVideoPlayer
window.MCAudioPlayer = MCAudioPlayer
window.MCTimedTrackable = MCTimedTrackable
window.MCAssetUploadButton = MCAssetUploadButton
window.mc = {
	version: '4.13',
	domain: 'mc.roqlogic.com',
	apiURL: '/api/', // DEPRECIATED
	user: { userKey: '', userMeta: {} },
  organization: {},
	account: { accountKey: '', accountMeta: {} },
	session: { sessionID: '' },
	doc: {},
	asset: {},
	campaign: {},
	admin: {},
	scrolled: false,
	uploaderWin: null,
	oldScreenClass: null,
	screenClass: null,
	browser: {},
	oc: {},
	isHeadless: getUrlParameter('headless') !== null,
	dialogs: null,
	shouldRunLoggedInChecks: false,
	tmpl: {
		paginationLink: $.template(
			null,
			'<li><a href="#" class="mc-btn ${classes}" data-page="${page}">${label}</a></li>'
		),
	},
	trackingData: {
		assetID: false,
		duration: false,
		event: false,
		eventID: false,
		// notificationID: false,
		notificationProviderID: false,
		pageID: false,
		postID: false,
		recipientID: false,
		sessionID: false,
	},
	fn: {
		init: function() {
			/*
			$(window).on("popstate",function(e){
				if(e.originalEvent.state !== null){
					var state = e.originalEvent.state;
					if(typeof state['mc-overlay'] !== 'undefined'){
						if(state['mc-overlay'] == true && typeof state.url !== 'undefined'){
							mc.fn.showOverlay(state.url);
						}else{
							$(document).find("title").html(state.title);
							history.replaceState(state,state.title,state.url);
							mc.fn.hideOverlay();
						}
					}
				}
			});
			*/

			// Run an "is logged in" check every minute
			if (mc.shouldRunLoggedInChecks) {
				mc.isLoggedInInterval = setInterval(function() {
					if (!mc.fn.isLoggedIn()) {
						mc.fn.logOut()
					}
				}, 60000)
			}

			mc.isApp = $('body').is('.browser-mc-app-y')

			mc.isRetinaDisplay = mc.fn.isRetinaDisplay()

			$('html').addClass((mc.isRetinaDisplay ? '' : 'no-') + 'retina')

			$('body')[mc.fn.isOverlay() ? 'addClass' : 'removeClass'](
				'mc-overlay'
			)

			if ($('#mc-body-header').length)
				$('body').addClass('mc-has-body-header')
			if ($('#mc-body-nav').length) $('body').addClass('mc-has-body-nav')
			if ($('#mc-body-aside').length)
				$('body').addClass('mc-has-body-aside')

			/*
			if(!Modernizr.input.placeholder){
				$('[placeholder]').focus(function() {
					var input = $(this);
					if(input.val() == input.attr('placeholder')) input.removeClass('placeholder').val('');
				}).blur(function(){
					var input = $(this);
					if(input.val() == '' || input.val() == input.attr('placeholder')) input.addClass('placeholder').val(input.attr('placeholder'));
				}).blur();

				$('[placeholder]').parents('form').submit(function(){
					$(this).find('[placeholder]').each(function() {
						var input = $(this);
						if(input.val() == input.attr('placeholder')) input.val('');
					})
				});
			}
			*/
			mc.oc.overlayWrap = $('#mc-overlay-wrap').on('click', function(e) {
				e.preventDefault()
				mc.fn.hideOverlay()
			})

			/*
			mc.oc.overlayClose = $('#mc-overlay-close').on('click',function(e){
				e.preventDefault();
				mc.fn.hideOverlay();
			});
			*/

			mc.oc.overlay = $('#mc-overlay')

			if (typeof CKEDITOR !== 'undefined') {
				CKEDITOR.config.font_names = CKEDITOR.config.font_names.replace(
					'Comic Sans MS, cursive',
					'Comic Sans MS, Marker Felt, sans-serif'
				)
			}

			$(document)
				.on('click', '.mc-content-link', function(e) {
          var currentTarget = $(e.currentTarget);
          var generatedContent = (currentTarget.hasClass('mc-pub-link-pdf')
            || currentTarget.hasClass('mc-pub-link-pod')
            || currentTarget.hasClass('mc-pub-link-epub')
          );
					if (!generatedContent && mc.inReagentiFrame) {
						e.preventDefault();
						mc.fn.navigateOnParent($(e.currentTarget).attr('href'));
						return false;
					}
          var currentUrl = new Url();
          if (currentUrl.query.headless === '1') {
            e.preventDefault();
            const href = currentTarget.attr('href');
            const url = new URL(new Url(href));
            url.searchParams.set('headless', '1');
            window.location.href = url.toString();
          }
				})
				.on('click', '.mc-content-preview-btn', function(e) {
					if (
						$('body').is('.page-browser') &&
						!$('body').is(
							'.mc-screen-mobile,.page-browser.mc-screen-smart'
						)
					) {
						return
					}
					mc.fn.showContentPreview($(this).data())
					return false
				})
				.on('click', '#mc-overlay-close', function(e) {
					e.preventDefault()
					window.parent.mc.fn.hideOverlay()
				})
				.on('click', '.mc-region-update-show-btn', function(e) {
					e.preventDefault()

					mc.fn.makeCKEditor('.mc-ckeditor')

					var region = $(this).closest('.mc-region')
					$(':input[data-mc-input-val-target]', region).each(
						function() {
							$(this).val(
								$($(this).data('mc-input-val-target')).html()
							)
						}
					)
					region.addClass('mc-status-edit')
					$('body').addClass('mc-region-status-edit')
				})
				.on('click', '.mc-region-update-cancel-btn', function(e) {
					e.preventDefault()
					$(this)
						.closest('.mc-region')
						.removeClass('mc-status-edit')
					$('body').removeClass('mc-region-status-edit')
				})
				.on('click', '.mc-region-update-save-btn', function(e) {
					e.preventDefault()
					$(this)
						.closest('.mc-region')
						.find('.mc-region-update-form')
						.trigger('submit')
				})
				.on('submit', '.mc-region-update-form', function(e) {
					var form = $(this),
						region = form.closest('.mc-region')

					form.find('.mc-region-update-input').remove()

					$(':input[data-mc-input-val-target]', region).each(
						function() {
							var value = $(this).val()
							$(
								'<input type="hidden" name="' +
									$(this).attr('name') +
									'"" class="mc-region-update-input" />'
							)
								.val(value)
								.appendTo(form)
						}
					)

					if (
						region.is(
							'#mc-region-asset-info, .mc-region-asset-info'
						)
					) {
						e.preventDefault()
						mc.fn.updateAsset({
							ajax: {
								data: $(this).serializeArray(),
							},
							success: function(response) {
								$(
									':input[data-mc-input-val-target]',
									region
								).each(function() {
									var property = $(this).attr('name')
									if (
										typeof response.asset[property] !=
										'undefined'
									) {
										var value = response.asset[property]
										$(
											$(this).data('mc-input-val-target')
										).html(value)
									}
								})
								region.removeClass('mc-status-edit')
								$('body').removeClass('mc-region-status-edit')
							},
						})
					} else if (
						region.is(
							'#mc-region-campaign-info, .mc-region-campaign-info'
						)
					) {
						e.preventDefault()
						mc.fn.updateCampaign({
							ajax: {
								data: $(this).serializeArray(),
							},
							success: function(response) {
								$(
									':input[data-mc-input-val-target]',
									region
								).each(function() {
									var property = $(this).attr('name')
									if (
										typeof response.campaign[property] !=
										'undefined'
									) {
										var value = response.campaign[property]
										$(
											$(this).data('mc-input-val-target')
										).html(value)
									}
								})
								region.removeClass('mc-status-edit')
								$('body').removeClass('mc-region-status-edit')
							},
						})
					} else if (
						region.is('#mc-region-doc-info, .mc-region-doc-info')
					) {
						e.preventDefault()
						mc.fn.updateDoc({
							ajax: {
								data: $(this).serializeArray(),
							},
							success: function(response) {
								$(
									':input[data-mc-input-val-target]',
									region
								).each(function() {
									var property = $(this).attr('name')
									if (
										typeof response.doc[property] !=
										'undefined'
									) {
										var value = response.doc[property]
										$(
											$(this).data('mc-input-val-target')
										).html(value)
									}
								})
								region.removeClass('mc-status-edit')
								$('body').removeClass('mc-region-status-edit')
							},
						})
					}
				})
				.on('submit', '.mc-charttable-form', function(e) {
					e.preventDefault()
					var form = $(this)

					$.ajax({
						type: 'POST',
						url: form.attr('action'),
						data: form.serializeArray(),
						dataType: 'json',
					}).done(function(response) {
						if (response.success) {
							if (
								typeof form.data('mc-charttable') !==
								'undefined'
							) {
								form.data('mc-charttable').remove()
								form.siblings(
									'div[data-highcharts-chart]'
								).remove()
							}

							var table = $(form.attr('data-mc-charttable-tmpl'))
								.tmpl(response)
								.addClass('mc-charttable-table')
								.insertAfter(form)
								.bind('highchartTable.beforeRender', function(
									event,
									highChartConfig
								) {
									highChartConfig.colors = Highcharts.getOptions().colors

									var chartType = $(this).attr(
										'data-graph-type'
									)
									if (
										typeof mc.highcharts.charts[
											chartType
										] !== 'undefined'
									) {
										$.extend(
											true,
											highChartConfig,
											mc.highcharts.charts[chartType]
										)
									}
								})

							$.each(form[0].attributes, function(i, v) {
								if (v.name.substr(0, 11) == 'data-graph-') {
									table.attr(v.name, v.value)
								}
							})
							form.data('mc-charttable', table.highchartTable())
							if (form.data('mc-charttable-table-visible')) {
								table.DataTable({
									responsive: true,
									dom: 't',
								})
								form.closest('.mc-region-body')
									.find('.highcharts-legend')
									.hide()
							} else {
								table.hide()
							}
						}
					})
				})
				.on('click', '#player-aside-toggle', function(e) {
					e.preventDefault()
					$('body').toggleClass('mc-player-aside-visible-y')
					setTimeout(function() {
						$(window).trigger('resize')
					}, 500)
				})
				.on('click', '#player-aside-close', function(e) {
					$('body').removeClass('mc-player-aside-visible-y')
					setTimeout(function() {
						$(window).trigger('resize')
					}, 500)
				})
				.on(
					'click',
					'.mc-body-aside-toggle, .mc-body-aside-toggle-sidebar',
					function(e) {
						e.preventDefault()
						$('body').toggleClass('mc-body-aside-active')
						setTimeout(function() {
							$(window).trigger('resize')
						}, 500)
					}
				)
				.on('click', '.mc-header-toggle', function(e) {
					e.preventDefault()
					$('body').toggleClass('mc-header-active')

					if ($('body').is('.mc-header-active')) {
						$(document).on('mousedown.mc-header-toggle', function(
							e
						) {
							if (
								$(e.target).closest('#mc-nav,.mc-header-toggle')
									.length == 0
							) {
								$('.mc-header-toggle:first').trigger('click')
								return false
							}
						})
					} else {
						$(document).off('.mc-header-toggle')
					}

					setTimeout(function() {
						$(window).trigger('resize')
					}, 500)
				})
				.on('click', '.mc-overlayme', function(e) {
					e.preventDefault()
					var href = $(this).attr('href')

					if ($(this).parents('.fancybox-wrap').length) {
						$.fancybox.close()
					}

					if ($(this).is('.mc-campaigntype-slug-deliveryecard')) {
						$('<div></div>').dialog({
							modal: true,
							draggable: false,
							resizable: false,
							autoOpen: true,
							title:
								$(this).attr('title') ||
								mc.fn.getLznString('eCard'),
							dialogClass: 'dialog-iframe',
							height: $(window).height() - 100,
							width: $(window).width() - 100,
							close: function() {
								$(this)
									.dialog('destroy')
									.remove()
							},
							open: function() {
								$(this).html(
									'<iframe src="' +
										href +
										'" style="width:100%;height:100%;"></iframe>'
								)
							},
						})
					} else {
						if (!mc.inReagentiFrame) {
							mc.fn.showOverlay(href)
						} else {
							mc.fn.navigateOnParent(href)
						}
					}
				})
				.on('click', '.mc-accordion-header', function(e) {
					e.preventDefault()
					$(this)
						.addClass('mc-status-focus')
						.toggleClass('mc-status-active')
						.siblings('.mc-accordion-header')
						.removeClass('mc-status-focus')
						.end()
						.next()
						.slideToggle(250)
				})
				.on(
					'click',
					'a.mc-dialog-show-btn,button.mc-dialog-show-btn',
					mc.fn.dialogLinkHandler
				)
				//          .on('click','a[href="/uploader/"]',mc.fn.uploaderLinkHandler)
				.on('click', '.mc-btn-group-radio .mc-btn', function(e) {
					$(this)
						.closest('.mc-btn-group-radio')
						.find('.mc-status-active')
						.removeClass('mc-status-active')
					$(this).addClass('mc-status-active')
				})
				.on('click', '.mc-create-campaign-btn', function(e) {
					e.preventDefault()

					var hackElID = 'mc-msie-iframe-hack'

					if (self !== top && $.browser.msie) {
						window.parent.$('#' + hackElID).remove()
						window.parent
							.$('body')
							.append(
								$('<div id="' + hackElID + '"></div>')
									.hide()
									.append($(this).clone())
							)
							.find('#' + hackElID + ' a:first')
							.trigger('click')
					} else {
						if ($(this).parents('.fancybox-wrap').length) {
							$.fancybox.close()
						}

						var campaignTitle =
								$(this).attr('data-mc-campaign-title') || '',
							pubSlug = $(this).attr('data-mc-pub-slug') || '',
							copyMeta = 'n',
							dialogTitle =
								$(this).attr('title') ||
								mc.fn.getLznString('Get Started'),
							campaignID = $(this).attr('data-mc-campaign-id')

						$.blockUI({ message: mc.fn.getLznString('Loading') })

						$.ajax({
							type: 'POST',
							url: '/api/public/gettemplates',
							dataType: 'json',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
								templateIDs: campaignID,
							},
						}).done(function(data) {
							$.unblockUI()
							if (data.items.length) {
								var template = data.items[0],
									isInvite =
										template.campaignTypeSlug == 'invite',
									useHost =
										isInvite &&
										template.campaignUseHost == 'y',
									dialog = $('<div></div>')
										.append(
											$(
												'#mc-tmpl-copy-campaign-dialog'
											).tmpl({
												campaignKey:
													template.templateKey,
												campaignUseHost: useHost
													? 'y'
													: 'n',
												pubSlug: pubSlug,
												copyMeta: copyMeta,
											})
										)
										.find('input[name="campaignTitle"]')
										.val(campaignTitle)
										.end()
										.dialog({
											modal: true,
											draggable: false,
											resizable: false,
											autoOpen: true,
											title: dialogTitle,
											dialogClass:
												'mc-dialog-enable-close',
											buttons: [
												{
													text: mc.fn.getLznString(
														'Continue'
													),
													click: function() {
														var form = $(this).find(
															'form'
														)
														if (useHost) {
															if (
																$(
																	'[name="campaignTitle"]',
																	form
																).val() == '' ||
																$(
																	'input[name="userName"]',
																	form
																).val() == '' ||
																$(
																	'input[name="userEmail"]',
																	form
																).val() == ''
															) {
																alert(
																	mc.fn.getLznString(
																		'All [[mc-invite]] fields are required'
																	)
																)
																return false
															}
														}
														$(this).dialog('close')
														if ($.browser.msie) {
															$(
																'#' + hackElID
															).remove()
															mc.fn.hideOverlay(
																true
															)
														}
														var overlayUrl = form.attr('action') + '?' + form.serialize()
														if (!mc.inReagentiFrame) {
															mc.fn.showOverlay(overlayUrl)
														} else {
															mc.fn.navigateOnParent(overlayUrl)
														}
													},
												},
											],
											close: function() {
												if ($.browser.msie) {
													$('#' + hackElID).remove()
												}
												$(this)
													.dialog('destroy')
													.remove()
											},
										})

								$.ajax({
									type: 'POST',
									url: '/api/public/getcampaigns',
									dataType: 'json',
									data: {
										userKey: mc.user.userKey,
										accountKey: mc.account.accountKey,
										templateIDs: template.templateID,
									},
								}).done(function(data) {
									if (data.items.length) {
										var cmenuID =
												'cmenu-campaign-copy-dialog-other-copies',
											cmenuHTML =
												'<menu id="' +
												cmenuID +
												'" type="context">'
										$.each(data.items, function(i, v) {
											cmenuHTML +=
												'<command label="' +
												v.campaignTitle +
												'" value="' +
												v.campaignKey +
												'"></command>'
										})
										cmenuHTML += '</menu>'
										$(
											'<div class="mc-other-copies-indicator" style="margin:20px 0 0;padding:20px 0 0;border-top:1px solid rgba(0,0,0,.1);"><p>' +
												mc.fn.getLznString(
													'You have used this [[mc-template]] before. <a [[copiesLinkAttributes]]>View other copies.</a>',
													{
														copiesLinkAttributes:
															'href="#' +
															cmenuID +
															'"',
													}
												) +
												'</p></div>'
										).appendTo(dialog)
										$(cmenuHTML)
											.appendTo(dialog)
											.on('click', 'command', function(
												e
											) {
												e.preventDefault()
												var overlayUrl = '/detail/campaign/' + $(this).attr('value')
												if (!mc.inReagentiFrame) {
													mc.fn.showOverlay(overlayUrl)
												} else {
													mc.fn.navigateOnParent(overlayUrl)
												}
												dialog.dialog('close')
											})
									}
								})
							}
						})
					}
				})
				.on('click', '.mc-copy-my-campaign-btn', function(e) {
					e.preventDefault()

					var hackElID = 'mc-msie-iframe-hack'

					if (self !== top && $.browser.msie) {
						window.parent.$('#' + hackElID).remove()
						window.parent
							.$('body')
							.append(
								$('<div id="' + hackElID + '"></div>')
									.hide()
									.append($(this).clone())
							)
							.find('#' + hackElID + ' a:first')
							.trigger('click')
					} else {
						var campaignTitle =
								$(this).attr('data-mc-campaign-title') || '',
							pubSlug = $(this).attr('data-mc-pub-slug') || '',
							copyMeta = 'y',
							dialogTitle =
								$(this).attr('title') ||
								mc.fn.getLznString('Create A Copy'),
							campaignID = $(this).attr('data-mc-campaign-id')

						$.blockUI({ message: mc.fn.getLznString('Loading') })

						$.ajax({
							type: 'POST',
							url: '/api/public/getcampaigns',
							dataType: 'json',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
								campaignIDs: campaignID,
							},
						}).done(function(data) {
							$.unblockUI()
							if (data.items.length) {
								var campaign = data.items[0],
									isInvite =
										campaign.campaignTypeSlug == 'invite',
									useHost =
										isInvite &&
										campaign.campaignUseHost == 'y',
									dialog = $('<div></div>')
										.append(
											$(
												'#mc-tmpl-copy-campaign-dialog'
											).tmpl({
												campaignKey:
													campaign.campaignKey,
												campaignUseHost: useHost
													? 'y'
													: 'n',
												pubSlug: pubSlug,
												copyMeta: copyMeta,
											})
										)
										.find('input[name="campaignTitle"]')
										.val(campaignTitle)
										.end()
										.dialog({
											modal: true,
											draggable: false,
											resizable: false,
											autoOpen: true,
											title: dialogTitle,
											dialogClass: 'content',
											buttons: [
												{
													text: mc.fn.getLznString(
														'Cancel'
													),
													click: function() {
														$(this).dialog('close')
														if ($.browser.msie) {
															$(
																'#' + hackElID
															).remove()
														}
													},
												},
												{
													text: mc.fn.getLznString(
														'Continue'
													),
													click: function() {
														var form = $(this).find(
															'form'
														)
														if (useHost) {
															if (
																$(
																	'[name="campaignTitle"]',
																	form
																).val() == '' ||
																$(
																	'input[name="userName"]',
																	form
																).val() == '' ||
																$(
																	'input[name="userEmail"]',
																	form
																).val() == ''
															) {
																alert(
																	mc.fn.getLznString(
																		'All [[mc-invite]] fields are required'
																	)
																)
																return false
															}
														}
														$(this).dialog('close')
														if ($.browser.msie) {
															$(
																'#' + hackElID
															).remove()
															mc.fn.hideOverlay(
																true
															)
														}
														var overlayUrl = form.attr('action') + '?' + form.serialize()
														if (!mc.inReagentiFrame) {
															mc.fn.showOverlay(overlayUrl)
														} else {
															mc.fn.navigateOnParent(overlayUrl)
														}
													},
												},
											],
											close: function() {
												$(this)
													.dialog('destroy')
													.remove()
											},
										})
							}
						})
					}
				})
				.on('click', '.mc-convert-to-template-btn', function(e) {
					e.preventDefault()

					var hackElID = 'mc-msie-iframe-hack'

					if (self !== top && $.browser.msie) {
						window.parent.$('#' + hackElID).remove()
						window.parent
							.$('body')
							.append(
								$('<div id="' + hackElID + '"></div>')
									.hide()
									.append($(this).clone())
							)
							.find('#' + hackElID + ' a:first')
							.trigger('click')
					} else {
						var campaignKey = $(this).attr('data-mc-campaign-key')

						$('<div></div>')
							.append(
								$('#mc-tmpl-convert-to-template-dialog').tmpl({
									campaignKey: campaignKey,
								})
							)
							.dialog({
								modal: true,
								draggable: false,
								resizable: false,
								autoOpen: true,
								title: mc.fn.getLznString(
									'Convert To Template'
								),
								dialogClass: 'content',
								buttons: [
									{
										text: mc.fn.getLznString('Cancel'),
										click: function() {
											$(this).dialog('close')
											if ($.browser.msie) {
												$('#' + hackElID).remove()
											}
										},
									},
									{
										text: mc.fn.getLznString('Continue'),
										click: function() {
											var form = $(this).find('form')
											$(this).dialog('close')
											if ($.browser.msie) {
												$('#' + hackElID).remove()
												mc.fn.hideOverlay(true)
											}
											var overlayUrl = form.attr('action') + '?' + form.serialize()
											if (!mc.inReagentiFrame) {
												mc.fn.showOverlay(overlayUrl)
											} else {
												mc.fn.navigateOnParent(overlayUrl)
											}
										},
									},
								],
								close: function() {
									$(this)
										.dialog('destroy')
										.remove()
								},
							})
					}
				})
				.on('click', '.mc-fav-toggle', function(e) {
					e.preventDefault()
					var itemType = ''

					if ($(this).is('[data-mc-asset-id]')) {
						itemType = 'asset'
					} else if ($(this).is('[data-mc-campaign-id]')) {
						itemType = 'campaign'
					} else if ($(this).is('[data-mc-template-id]')) {
						itemType = 'template'
					} else if ($(this).is('[data-mc-doc-id]')) {
						itemType = 'doc'
					}

					if (itemType != '') {
						var itemTypeID = $(this).data('mc-' + itemType + '-id'),
							fav = $(this).is('.mc-fav-y') ? 'n' : 'y'
						if (itemTypeID != '') {
							mc.fn.fav({
								ajax: {
									data: {
										favorite: fav,
										itemType: itemType,
										itemTypeID: itemTypeID,
									},
								},
								success: function() {
									var textStatus = mc.fn.getLznString(
											fav == 'y'
												? 'Remove Favorite'
												: 'Favorite'
										),
										toggleEls = $(
											'.mc-fav-toggle[data-mc-' +
												itemType +
												'-id="' +
												itemTypeID +
												'"]'
										)
									toggleEls
										.filter('.mc-tooltip')
										.attr('title', textStatus)
									toggleEls
										.not('.mc-tooltip')
										.html(textStatus)
								},
							})
						}
					}
				})
				.on('click', '.mc-archive-toggle', function(e) {
					e.preventDefault()
					var itemType = ''

					if ($(this).is('[data-mc-asset-id]')) {
						itemType = 'asset'
					} else if ($(this).is('[data-mc-campaign-id]')) {
						itemType = 'campaign'
					} else if ($(this).is('[data-mc-template-id]')) {
						itemType = 'template'
					} else if ($(this).is('[data-mc-doc-id]')) {
						itemType = 'doc'
					}

					if (itemType != '') {
						var itemTypeID = $(this).data('mc-' + itemType + '-id'),
							archive = $(this).is('.mc-archive-y') ? 'n' : 'y'
						if (itemTypeID != '') {
							var params = {
								ajax: {
									data: {
										archive: archive,
										itemType: itemType,
										itemTypeID: itemTypeID,
									},
								},
								success: function() {
									$(
										'.mc-archive-toggle[data-mc-' +
											itemType +
											'-id="' +
											itemTypeID +
											'"]'
									).html(
										mc.fn.getLznString(
											archive == 'y'
												? 'Unarchive'
												: 'Archive'
										)
									)
								},
							}

							if (mc.user.userMeta.showAlertArchive == 'n') {
								mc.fn.archive(params)
							} else {
								$(
									'#mc-alert-archive-' +
										($(this).is('.mc-archive-y')
											? 'y'
											: 'n') +
										'-public-' +
										($(this).is('.mc-public-y') ? 'y' : 'n')
								)
									.data('mc-archive-params', params)
									.dialog('open')
							}
						}
					}
				})
				.on('click', '.mc-campaign-pub-link', function(e) {
					e.preventDefault()
					mc.fn.publishCampaign({
						campaignKey: $(this).data('mc-campaign-key'),
						pubSlug: $(this).data('mc-pub-slug'),
					})
				})
				.on(
					'click',
					'menu[id^="cmenu-pubs-campaigntype"] command',
					function(e) {
						mc.fn.publishCampaign({
							campaignKey: $('a.cmenu-active').attr(
								'data-campaignkey'
							),
							pubSlug: $(this)
								.attr('value')
								.toLowerCase(),
						})
					}
				)
				.on('click', '.mc-asset-pub-link', function(e) {
					e.preventDefault()
					mc.fn.publishAsset({
						assetKey: $(this).data('mc-asset-key'),
						assetType: $(this).data('mc-asset-type'),
						pubSlug: $(this).data('mc-pub-slug'),
					})
				})
				.on('click', 'menu[id="cmenu-pubs-doc"] command', function(e) {
					var docKey = $('a.cmenu-active').attr('data-dockey'),
						pubSlug = $(this)
							.attr('value')
							.toLowerCase(),
						pubHref = '/publish/doc/' + pubSlug + '/' + docKey

					switch (pubSlug) {
						case 'pinterest':
						case 'prersecard':
							window.open(pubHref, '_blank')
							break

						case 'ecard':
							var d = $('<div></div>').dialog({
								modal: true,
								draggable: false,
								resizable: false,
								autoOpen: true,
								title:
									$(this).attr('title') ||
									mc.fn.getLznString('Send eCard'),
								dialogClass: 'dialog-iframe',
								height: $(window).height() - 100,
								width: $(window).width() - 100,
								close: function() {
									$(this)
										.dialog('destroy')
										.remove()
								},
								open: function() {
									$(this).html(
										'<iframe src="' +
											pubHref +
											'" style="width:100%;height:100%;"></iframe>'
									)
								},
							})
							break

						default:
							mc.fn.showOverlay(pubHref)
							break
					}
				})
				.on('click', '#cmenu-user-account-list command', function(e) {
					window.location.href = $(this).attr('value')
				})
				.on('click', '#cmenu-authuser command', function(e) {
					switch ($(this).attr('value')) {
						case 'profile':
							mc.fn.showOverlay('/profile/')
							break

						case 'logout':
							mc.fn.logOut()
							break
					}
				})
				.on('click', '.mc-dialog-article-show', function(e) {
					e.preventDefault()

					$.blockUI({ message: mc.fn.getLznString('Loading') })

					$.ajax({
						type: 'POST',
						url: '/api/public/getArticles',
						cache: false,
						data: {
							userKey: mc.user.userKey,
							accountKey: mc.account.accountKey,
							articleSlug: $(this).attr('href'),
						},
						dataType: 'json',
					})
						.done(function(response) {
							if (response.success) {
								$('#mc-article-dialog-body').html(
									response.items[0].articleBody
								)
								mc.oc.articleDialog
									.dialog(
										'option',
										'title',
										response.items[0].articleTitle
									)
									.dialog('open')
							} else {
								if (
									response &&
									response.error &&
									response.error.message
								) {
									mc.alerts.warn(
										mc.fn.getLznString('[[error]]', {
											error: response.error.message,
										})
									)
								} else {
									mc.alerts.warn(
										mc.fn.getLznString(
											'An unknown error occurred'
										)
									)
								}
							}
						})
						.fail(function() {
							mc.alerts.warn(
								mc.fn.getLznString('An unknown error occurred')
							)
						})
						.always(function() {
							$.unblockUI()
						})
				})
				.on('success.mc-converttotemplate', function(e, data) {
					$(
						'.mc-campaign[data-mc-campaign-id="' +
							data.template.templateID +
							'"]'
					)
						.removeClass('mc-campaign')
						.addClass('mc-template')
						.removeAttr('data-mc-campaign-id')
						.attr('data-mc-template-id', data.template.templateID)
				})
				.on('success.mc-favorite', function(e, data) {
					var item = data.item,
						itemType = ''

					if (item.assetID) {
						itemType = 'asset'
					} else if (item.campaignID) {
						itemType = 'campaign'
					} else if (item.templateID) {
						itemType = 'template'
					} else if (item.docID) {
						itemType = 'doc'
					}
					if (itemType != '') {
						$(
							'.mc-fav-' +
								(item[itemType + 'Fav'] == 'y' ? 'n' : 'y') +
								'[data-mc-' +
								itemType +
								'-id="' +
								item[itemType + 'ID'] +
								'"]'
						)
							.removeClass(
								'mc-fav-' +
									(item[itemType + 'Fav'] == 'y' ? 'n' : 'y')
							)
							.addClass('mc-fav-' + item[itemType + 'Fav'])
						mc.alerts.success(
							itemType.charAt(0).toUpperCase() +
								itemType.slice(1) +
								' ' +
								(item[itemType + 'Fav'] == 'y'
									? 'added to Favorites'
									: 'removed from Favorites')
						)
					}
				})
				.on('success.mc-archive', function(e, data) {
					var item = data.item,
						itemType = ''

					if (item.assetID) {
						itemType = 'asset'
					} else if (item.campaignID) {
						itemType = 'campaign'
					} else if (item.templateID) {
						itemType = 'template'
					} else if (item.docID) {
						itemType = 'doc'
					}

					if (itemType != '') {
						$(
							'.mc-archive-' +
								(item[itemType + 'Archive'] == 'y'
									? 'n'
									: 'y') +
								'[data-mc-' +
								itemType +
								'-id="' +
								item[itemType + 'ID'] +
								'"]'
						)
							.removeClass(
								'mc-archive-' +
									(item[itemType + 'Archive'] == 'y'
										? 'n'
										: 'y')
							)
							.addClass(
								'mc-archive-' + item[itemType + 'Archive']
							)
						mc.alerts.success(
							itemType.charAt(0).toUpperCase() +
								itemType.slice(1) +
								' ' +
								(item[itemType + 'Archive'] == 'y'
									? 'archived'
									: 'unarchived')
						)
						$(
							'.mc-public-' +
								(item[itemType + 'Public'] == 'y' ? 'n' : 'y') +
								'[data-mc-' +
								itemType +
								'-id="' +
								item[itemType + 'ID'] +
								'"]'
						)
							.removeClass(
								'mc-public-' +
									(item[itemType + 'Public'] == 'y'
										? 'n'
										: 'y')
							)
							.addClass('mc-public-' + item[itemType + 'Public'])
					}
				})
				.on('click', '.mc-checklist label', function(e) {
					$(this)
						.closest('li')
						.toggleClass('mc-status-active')
				})
				.on('click', '.mc-event-edit-btn', function(e) {
					e.preventDefault()

					var el = $(this),
						eventID = el.attr('data-mc-event-id'),
						eventName = el.attr('data-mc-event-name')

					$(
						'<div><input type="hidden" autofocus="autofocus" /><input type="text" name="eventName" value="" /></div>'
					)
						.find('[name="eventName"]')
						.val(eventName)
						.end()
						.dialog({
							modal: true,
							draggable: false,
							resizable: false,
							autoOpen: true,
							title: mc.fn.getLznString('Rename Event'),
							dialogClass: 'mc-dialog-enable-close',
							close: function() {
								$(this)
									.dialog('destroy')
									.remove()
							},
							buttons: [
								{
									text: mc.fn.getLznString('Save'),
									click: function() {
										var dialog = $(this),
											newEventName = $(
												'input[name="eventName"]',
												dialog
											).val()

										$.blockUI({
											message: mc.fn.getLznString(
												'Saving'
											),
										})

										el.trigger('before.mc-event-edit', {
											el: el,
											eventID: eventID,
											eventName: newEventName,
										})

										$.ajax({
											type: 'POST',
											url: '/api/public/updateevent',
											dataType: 'json',
											data: {
												userKey: mc.user.userKey,
												accountKey:
													mc.account.accountKey,
												eventID: eventID,
												eventName: newEventName,
											},
										})
											.done(function(response) {
												if (response.success) {
													el.attr(
														'data-mc-event-name',
														response.item.eventName
													).trigger(
														'success.mc-event-edit',
														{
															el: el,
															event:
																response.item,
														}
													)
													dialog.dialog('close')
												} else {
													if (
														response &&
														response.error &&
														response.error.message
													) {
														el.trigger(
															'error.mc-event-edit',
															{
																el: el,
																eventID: eventID,
																eventName: newEventName,
																error:
																	response
																		.error
																		.message,
															}
														)
														mc.alerts.warn(
															mc.fn.getLznString(
																'[[error]]',
																{
																	error:
																		response
																			.error
																			.message,
																}
															)
														)
													} else {
														el.trigger(
															'error.mc-event-edit',
															{
																el: el,
																eventID: eventID,
																eventName: newEventName,
																error: mc.fn.getLznString(
																	'An unknown error occurred'
																),
															}
														)
														mc.alerts.warn(
															mc.fn.getLznString(
																'An unknown error occurred'
															)
														)
													}
												}
											})
											.fail(function() {
												el.trigger(
													'fail.mc-event-edit',
													{
														el: el,
														eventID: eventID,
														eventName: newEventName,
													}
												)
												mc.alerts.warn(
													mc.fn.getLznString(
														'An unknown error occurred'
													)
												)
											})
											.always(function(
												response,
												textStatus,
												jqXHR
											) {
												el.trigger(
													'always.mc-event-edit',
													{
														el: el,
														eventID: eventID,
														eventName: newEventName,
													}
												)
												$.unblockUI()
											})
									},
								},
							],
						})
				})
				.on('click', '.mc-add-service-btn', function(e) {
					e.preventDefault()

					var serviceTypeSlug = $(this).attr(
							'data-mc-service-type-slug'
						),
						currentURL = new Url(),
						successURL = new Url(
							'/service/complete/' + serviceTypeSlug
						),
						serviceURL = new Url('/service/add/' + serviceTypeSlug)

					if (mc.fn.isOverlay()) {
						var overlayURL = currentURL
						overlayURL.host = ''
						overlayURL.protocol = ''
						delete overlayURL.query.mcoverlay

						currentURL = new Url(window.top.location.href)
						currentURL.query.mcoverlayurl = overlayURL.toString()
					}

					successURL.query.redirectEncoded = btoa(
						currentURL.toString()
					)
					serviceURL.query.redirect = successURL.toString()

					window.top.location = serviceURL.toString()
				})
				.on('flick', function(e) {
					if (
						$('body').is(
							'.mc-screen-mobile,.mc-screen-smart,.mc-screen-tablet'
						) &&
						!$('body').is('.mc-overlay')
					) {
						if (e.orientation == 'horizontal') {
							if (
								!$('body').is('.mc-body-aside-active') &&
								((e.direction == -1 &&
									!$('body').is('.mc-header-active')) ||
									(e.direction == 1 &&
										$('body').is('.mc-header-active')))
							) {
								$('.mc-header-toggle:first').trigger('click')
								e.preventDefault()
							} else if (
								$('body').is('.mc-has-body-aside') &&
								((e.direction == -1 &&
									$('body').is('.mc-body-aside-active')) ||
									(e.direction == 1 &&
										!$('body').is('.mc-body-aside-active')))
							) {
								$('.mc-body-aside-toggle:first').trigger(
									'click'
								)
								e.preventDefault()
							}
						}
					}
				})
				.on('keydown', function(e) {
					if ((e.ctrlKey || e.metaKey) && e.keyCode == 73) {
						$('#dialog-mc-get-support-info').dialog({
							modal: true,
							width: $(window).width() * 0.6,
							draggable: false,
							resizable: false,
							autoOpen: true,
							buttons: [
								{
									text: mc.fn.getLznString('Close'),
									click: function() {
										$(this).dialog('close')
									},
								},
								{
									class: 'mc-clipboard',
									id: 'mc-clipboard-copy-all',
									'data-clipboard-target':
										'#support-get-info-complete',
									text: 'Copy All',
									click: function() {
										return
									},
								},
							],
						})
					}
				})

			if (mc.isApp) {
				$(document).on('click', '.mc-in-app-browser', function(e) {
					e.preventDefault()
					// Find the top most frame
					var topWindow = window
					while (topWindow.parent != topWindow) {
						topWindow = window.parent
					}
					// --
					var currentElement = $(this)

					topWindow.$('<div></div>').dialog({
						modal: true,
						draggable: false,
						resizable: false,
						autoOpen: true,
						title: currentElement.attr('title') || '',
						dialogClass:
							'mc-dialog-in-app-browser mc-dialog-enable-close',
						close: function() {
							$(this)
								.dialog('destroy')
								.remove()
						},
						open: function() {
							$(this).html(
								'<iframe src="' +
									currentElement.attr('href') +
									'" width="100%" height="100%" frameborder="0" marginwidth="0" marginheight="0" allowtransparency="true" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>'
							)
						},
					})
				})
			}

			mc.fn.makeSearchBar($('.mc-searchbar'))
			mc.fn.makeAutoComplete($('.mc-autocomplete'))
			mc.fn.makeInfiniteScroll($('.mc-infinite-scroll'))
			mc.fn.makeDataTable($('table.mc-datatable'))

			mc.fn.processMoments()

			$('.mc-accordion-header.mc-accordion-header-init-on').trigger(
				'click'
			)

			$('.mc-tabs').tabs()

			$('.mc-sort-toggle-wrap')
				.on('click', '.mc-sort-toggle', function(e) {
					e.preventDefault()
					var wrap = $(this).closest('.mc-sort-toggle-wrap')
					if ($(this).is('.mc-status-active')) {
						$(this).toggleClass('mc-sort-toggle-desc')
					} else {
						wrap.find(
							'.mc-sort-toggle.mc-status-active'
						).removeClass('mc-status-active')
					}
					$(this).addClass('mc-status-active')
					var form = wrap.is('[data-mc-sort-toggle-form]')
							? $(wrap.data('mc-sort-toggle-form'))
							: $(this).closest('form'),
						fieldname = wrap.is('[data-mc-sort-toggle-fieldname]')
							? wrap.data('mc-sort-toggle-fieldname')
							: 'sort',
						val = $(this).data('mc-sort-value')
					if ($(this).is('.mc-sort-toggle-desc')) val += '-desc'
					$('[name="' + fieldname + '"]', form)
						.val(val)
						.trigger('change')
					$('[name="p"]', form).val(1)
					form.trigger('submit')
				})
				.each(function(index) {
					var form = $(this).is('[data-mc-sort-toggle-form]')
							? $($(this).attr('data-mc-sort-toggle-form'))
							: $(this).closest('form'),
						fieldname = $(this).is(
							'[data-mc-sort-toggle-fieldname]'
						)
							? $(this).attr('data-mc-sort-toggle-fieldname')
							: 'sort',
						defaultSort = $('[name="' + fieldname + '"]', form)
							.val()
							.split('-'),
						toggle = $('.mc-sort-toggle', $(this))
							.filter(
								'[data-mc-sort-value|="' + defaultSort[0] + '"]'
							)
							.addClass('mc-status-active')
					if (defaultSort[1] == 'desc')
						toggle.addClass('mc-sort-toggle-desc')
				})

			//setup article dialog
			mc.oc.articleDialog = $('#dialog-mc-article').dialog({
				modal: true,
				draggable: false,
				resizable: false,
				autoOpen: false,
				dialogClass: 'mc-dialog-enable-close',
				open: function(event, ui) {
					$(window)
						.on('resize.mc-article-dialog', function() {
							requestAnimationFrame(function() {
								mc.oc.articleDialog.dialog('option', {
									height: Math.min(
										$(window).height() - 100,
										1000
									),
									width: Math.min(
										$(window).width() - 100,
										800
									),
								})
							})
						})
						.trigger('resize')
				},
				close: function(event, ui) {
					$(window).off('resize.mc-article-dialog')
				},
			})

			$(
				'#mc-alert-archive-n-public-n, #mc-alert-archive-n-public-y, #mc-alert-archive-y-public-n'
			).dialog(
				$.extend(true, {}, mc.alerts.config, {
					buttons: [
						{
							text: mc.fn.getLznString('Cancel'),
							class: 'cancel',
							click: function() {
								if (
									$('input[name="hide"]', $(this)).is(
										':checked'
									)
								)
									mc.fn.updateUserMeta(
										$('input[name="hide"]', $(this)).attr(
											'value'
										),
										'n'
									)
								$(this).dialog('close')
							},
						},
						{
							text: mc.fn.getLznString('Ok'),
							click: function() {
								if (
									$('input[name="hide"]', $(this)).is(
										':checked'
									)
								)
									mc.fn.updateUserMeta(
										$('input[name="hide"]', $(this)).attr(
											'value'
										),
										'n'
									)
								mc.fn.archive($(this).data('mc-archive-params'))
								$(this).dialog('close')
							},
						},
					],
				})
			)

			/*
			if(mc.user.userMeta.mcAlertTlSendingLimit && !$('body').is('.page-page-tl-sending-limit')){
				mc.alerts.warn('You have reached your email sending limit and some emails may not have been delived.<br /><br /><a href="/page/tl-sending-limit" class="mc-btn">Fix Sending Limit Errors</a>',{'fixed':true});
			}
			*/

			//initialize date range picker
			$('.mc-daterangepicker').each(function() {
				var datePickerElement = $(this),
					datePickerForm = $(this).closest('.mc-daterange-form'),
					datePickerInput = datePickerForm.find(
						'.mc-daterangepicker-input'
					)
				datePickerInputVal = datePickerInput.val()

				datePickerElement.daterangepicker({
					onClose: function() {
						datePickerInputVal = datePickerInput.val()
						datePickerForm.trigger('submit')
					},
				})
			})

			var url = new Url(document.location.href)
			if (
				typeof url.query.mcoverlayurl != 'undefined' &&
				url.query.mcoverlayurl != ''
			) {
				mc.fn.showOverlay(url.query.mcoverlayurl)
			}

			$('.mc-charttable-form').trigger('submit')

			$(document).trigger('mcload')

			mc.fn.prepareTooltips()
			mc.fn.cacheDialogs()
			mc.fn.prepareVideoPlayers()

			mc.fn.prepareContextMenus()

			$(window)
				.on('scroll', function() {
					requestAnimationFrame(function() {
						mc.scrolled = true
						if (!$.browser.msie) {
							mc.fn.repositionDialogs()
						}
					})
				})
				.on('resize', function() {
					requestAnimationFrame(function() {
						mc.fn.repositionDialogs()
						mc.fn.setScreenClass()

						var overlay = $('#mc-overlay-iframe')
						if (overlay.length) {
							$(overlay[0].contentWindow.window).trigger('resize')
						}
					})
				})
				.on('orientationchange', function() {
					mc.fn.repositionDialogs()
					mc.fn.setScreenClass()
					var overlay = $('#mc-overlay-iframe')
					if (overlay.length) {
						$(overlay[0].contentWindow.window).trigger(
							'orientationchange'
						)
					}
				})
				.trigger('scroll')
				.trigger('resize')
		},
		getItemLocalizations: function(options, callback) {
			options = $.extend(
				true,
				{
					userKey: mc.user.userKey,
					accountKey: mc.account.accountKey,
					itemType: '',
					itemTypeID: mc.campaign.campaignID,
				},
				options
			)

			$.ajax({
				type: 'POST',
				url: '/api/public/getItemLocalizations',
				dataType: 'json',
				data: options,
			}).always(function(response) {
				callback(response)
			})
		},
		isRetinaDisplay: function() {
			//return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
			//return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
			return (
				(window.matchMedia &&
					(window.matchMedia(
						'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)'
					).matches ||
						window.matchMedia(
							'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)'
						).matches)) ||
				(window.devicePixelRatio && window.devicePixelRatio >= 2)
			)
		},
		getScreenClass: function() {
			var width = $(window).width()

			if (width > 1024) {
				return 'mc-screen-wide'
			} else if (width > 768) {
				return 'mc-screen-desktop'
			} else if (width > 480) {
				return 'mc-screen-tablet'
			} else if (width > 320) {
				return 'mc-screen-smart'
			} else {
				return 'mc-screen-mobile'
			}
		},
		setScreenClass: function() {
			var screenClass = mc.fn.getScreenClass()
			var hasChanged = !$('body').is('.' + screenClass)

			$('body')
				.removeClassPrefix('mc-screen-')
				.addClass(screenClass)

			mc.oldScreenClass = mc.screenClass
			mc.screenClass = screenClass

			if (hasChanged) {
				$(window).trigger('mcscreenchange', {
					screenClass: screenClass,
				})
			}
		},
		makeCKEditor: function(els) {
			$(els)
				.not('.mc-ckeditor-init')
				.addClass('mc-ckeditor-init')
				.ckeditor({
					disableAutoInline: true,
					fillEmptyBlocks: false,
					removePlugins: 'elementspath,scayt,wsc',
					resize_enabled: false,
					skin: 'minimalist',
					toolbar: [
						{
							name: 'basicstyles',
							items: [
								'Bold',
								'Italic',
								'Underline',
								'Superscript',
							],
						},
						{
							name: 'paragraph',
							items: [
								'JustifyLeft',
								'JustifyCenter',
								'JustifyRight',
							],
						},
						{
							name: 'lists',
							items: ['NumberedList', 'BulletedList'],
						},
						{ name: 'links', items: ['Link', 'Unlink'] },
						{ name: 'color', items: ['TextColor'] },
					],
				})
		},
		makeDataTable: function(els) {
			els.each(function() {
				var table = $(this),
					columns = [],
					sort = [],
					exportColumns = [],
					datatableDom = table.is('[data-mc-datatable-dom]')
						? table.data('mc-datatable-dom')
						: 'ftri',
					datatableExportFilename = table.is(
						'[data-mc-datatable-export-filename]'
					)
						? table.data('mc-datatable-export-filename')
						: '',
					datatableResponseItems = table.is(
						'[data-mc-datatable-response-items]'
					)
						? table.data('mc-datatable-response-items')
						: 'items',
					datatableResponseTotalItems = table.is(
						'[data-mc-datatable-response-total-items]'
					)
						? table.data('mc-datatable-response-total-items')
						: 'pagination.totalItems',
					datatableScrollHeight = table.is(
						'[data-mc-datatable-scroll-height]'
					)
						? table.data('mc-datatable-scroll-height')
						: '',
					datatableStateSave =
						table.is('[data-mc-datatable-state-save]') &&
						table.attr('data-mc-datatable-state-save') == 'true',
					datatableServerSide = !(
						table.is('[data-mc-datatable-serverside]') &&
						table.attr('data-mc-datatable-serverside') == 'false'
					)
				$('thead tr', table).append(
					'<th data-mc-datatable-column-classname="control" data-mc-datatable-column-orderable="false" data-mc-datatable-column-render=""></th>'
				) //creates column for nested table control
				$('thead th', table).each(function(i) {
					var colHeader = $(this),
						colConfig = {}

					switch (colHeader.data('mc-datatable-column-action')) {
						case 'edit':
							colConfig.defaultContent =
								'<a href="" class="mc-datatable-row-edit-btn" style="text-align:center;">' +
								mc.fn.getLznString('Edit') +
								'</a>'
							break

						case 'delete':
							colConfig.defaultContent =
								'<a href="" class="mc-datatable-row-delete-btn" style="text-align:center;">' +
								mc.fn.getLznString('Delete') +
								'</a>'
							break
					}

					if (colHeader.is('[data-mc-datatable-column-data]')) {
						colConfig.data = colHeader.attr(
							'data-mc-datatable-column-data'
						)
					}

					if (colHeader.is('[data-mc-datatable-column-type]')) {
						colConfig.type = colHeader.attr(
							'data-mc-datatable-column-type'
						)
					}

					if (colHeader.is('[data-mc-datatable-column-classname]')) {
						colConfig.className = colHeader.attr(
							'data-mc-datatable-column-classname'
						)
					}

					if (colHeader.is('[data-mc-datatable-column-visible]')) {
						colConfig.visible =
							colHeader.attr(
								'data-mc-datatable-column-visible'
							) == 'true'
					}

					if (colHeader.is('[data-mc-datatable-column-orderable]')) {
						colConfig.orderable =
							colHeader.attr(
								'data-mc-datatable-column-orderable'
							) == 'true'
					}

					if (colHeader.is('[data-mc-datatable-column-config]')) {
						$.extend(
							colConfig,
							colHeader.attr('data-mc-datatable-column-config')
						)
					}

					if (colHeader.is('[data-mc-datatable-column-template]')) {
						var colTemplateEl = $(
							colHeader.attr('data-mc-datatable-column-template')
						)
						colConfig.render = function(data, type, row, meta) {
							return $('<div />')
								.append(colTemplateEl.tmpl(row))
								.html()
						}
					}

					if (colHeader.is('[data-mc-datatable-column-render]')) {
						var colTemplate = $.template(
							null,
							colHeader.attr('data-mc-datatable-column-render')
						)
						colConfig.render = function(data, type, row, meta) {
							return $('<div />')
								.append($.tmpl(colTemplate, row))
								.html()
						}
					}

					if (
						colHeader.is('[data-mc-datatable-column-editable]') &&
						colHeader.attr('data-mc-datatable-column-editable') ==
							'true'
					) {
						colConfig.className =
							typeof colConfig.className == 'undefined'
								? 'mc-datatable-column-editable'
								: colConfig.className +
								  ' mc-datatable-column-editable'
					}

					if (
						colHeader.is('[data-mc-datatable-column-sort]') &&
						colHeader.attr('data-mc-datatable-column-sort') != ''
					) {
						var colSort = colHeader
								.attr('data-mc-datatable-column-sort')
								.split('-'),
							colSortPriority = parseInt(colSort[0], 10),
							colSortDir = colSort[1]
						sort.splice(colSortPriority, 0, [i, colSortDir])
					}

					if (
						!colHeader.is('[data-mc-datatable-column-export]') ||
						colHeader.attr('data-mc-datatable-column-sort') ==
							'true'
					) {
						exportColumns.push(i)
					}

					columns.push(colConfig)
				})

				var getForm = $(table.data('mc-datatable-get-form')).on(
						'submit',
						function(e) {
							e.preventDefault()

							var form = $(this)

							if (!datatableServerSide) {
								if (typeof form.data('mc-xhr') !== 'undefined')
									form.data('mc-xhr').abort()
								table
									.DataTable()
									.clear()
									.draw()
							}

							table.trigger('before.mc-datatable-get', {
								table: table,
								form: form,
							})

							var xhr = $.ajax({
								type: 'POST',
								url: form.attr('action'),
								data: form.serializeArray(),
								dataType: 'json',
							})
								.done(function(response) {
									if (response.success) {
										var { items, pagination } = response
										var totalItems = parseInt(
											pagination.totalItems
										)
										var callback = form.data(
											'datatables-callback'
										)

										table.data(
											'mc-datatable-get-response',
											response
										)

										if (
											table.is(
												'[data-mc-datatable-total-items]'
											)
										) {
											var totalItemsOverride = parseInt(
												table.data(
													'mc-datatable-total-items'
												),
												10
											)
											if (totalItemsOverride > 0) {
												items = items.slice(
													0,
													totalItemsOverride
												)
												totalItems = Math.min(
													items.length,
													totalItemsOverride
												)
											}
										}

										callback({
											draw: parseInt(
												response.custom.draw,
												10
											),
											recordsTotal: totalItems,
											recordsFiltered: totalItems,
											data: items,
										})

										table
											.DataTable()
											.columns.adjust()
											.responsive.recalc()

										table.trigger(
											'success.mc-datatable-get',
											{
												xhr: xhr,
												table: table,
												form: form,
												response: response,
											}
										)
										mc.fn.processMoments()
									} else {
										table.trigger(
											'error.mc-datatable-get',
											{
												xhr: xhr,
												table: table,
												form: form,
												response: response,
											}
										)
									}
								})
								.fail(function() {
									table.trigger('fail.mc-datatable-get', {
										xhr: xhr,
										table: table,
										form: form,
									})
								})
								.always(function(response, textStatus, jqXHR) {
									table.trigger('always.mc-datatable-get', {
										xhr: xhr,
										table: table,
										form: form,
										response: response,
									})
								})

							form.data('mc-xhr', xhr)
						}
					),
					datatableConfig = {
						destroy: true,
						processing: true,
						deferRender: true,
						responsive: {
							details: {
								type: 'column',
								target: -1,
							},
						},
						columnDefs: [
							{
								width: '30px',
								targets: -1,
							},
						],
						paging: datatableServerSide,
						serverSide: datatableServerSide,
						dom: datatableDom,
						language: {
							info: '_TOTAL_ Results',
							processing: '',
						},
						columns: columns,
						stateSave: datatableStateSave,
						order: sort,
						tableTools: {
							sSwfPath:
								'/assets/datatables/extensions/TableTools/swf/copy_csv_xls_pdf.swf',
							aButtons: [
								{
									sExtends: 'collection',
									sButtonText: 'Export',
									aButtons: [
										{
											sExtends: 'copy',
											mColumns: exportColumns,
										},
										{
											sExtends: 'csv',
											mColumns: exportColumns,
											sTitle: datatableExportFilename,
										},
									],
								},
							],
						},
						ajax: function(data, callback, settings) {
							if (datatableServerSide) {
								var sort = []
								$.each(data.order, function(i, val) {
									sort.push(
										data.columns[val.column].data +
											'-' +
											val.dir
									)
								})

								if ($('[name="sort"]', getForm).length == 0)
									getForm.append(
										'<input type="hidden" name="sort" />'
									)
								$('[name="sort"]', getForm).val(sort.join(','))

								if ($('[name="limit"]', getForm).length == 0)
									getForm.append(
										'<input type="hidden" name="limit" />'
									)
								if (data.length)
									$('[name="limit"]', getForm).val(
										data.length
									)

								if ($('[name="offset"]', getForm).length == 0)
									getForm.append(
										'<input type="hidden" name="offset" />'
									)
								if (data.start)
									$('[name="offset"]', getForm).val(
										data.start
									)

								if ($('[name="keyword"]', getForm).length == 0)
									getForm.append(
										'<input type="hidden" name="keyword" />'
									)
								if (data.search.value)
									$('[name="keyword"]', getForm).val(
										data.search.value
									)
							}

							if ($('[name="custom[draw]"]', getForm).length == 0)
								getForm.append(
									'<input type="hidden" name="custom[draw]" />'
								)
							if (data.draw)
								$('[name="custom[draw]"]', getForm).val(
									data.draw
								)

							getForm
								.data('datatables-callback', callback)
								.submit()
						},
					}

				if (datatableScrollHeight != 'full') {
					table.on('init.dt', function() {
						$(window).smartresize(function() {
							table.DataTable().draw()
						})
					})
				}

				var scrollerConfigIndex = datatableConfig.dom.indexOf('S')
				if (datatableServerSide && scrollerConfigIndex === -1) {
					datatableConfig.dom += 'S'
				} else if (!datatableServerSide && scrollerConfigIndex !== -1) {
					var newDom = datatableConfig.dom.replace('S', '')
					datatableConfig.dom = newDom
				}
				if (datatableScrollHeight != '') {
					if (datatableScrollHeight == 'full') {
						datatableScrollHeight =
							$(window).height() - table.offset().top - 170 + 'px'
						table.on('init.dt', function() {
							$(window).smartresize(function() {
								var scrollBody = table.closest(
										'.dataTables_scrollBody'
									),
									height =
										$(window).height() -
										scrollBody.offset().top -
										70,
									settings = table.DataTable().settings()

								settings[0].oScroll.sY = height + 'px'
								scrollBody.height(height)
								table.DataTable().columns.adjust()
							})
							$(window).trigger('resize')
						})
					}
					datatableConfig.scrollY = datatableScrollHeight
					/*
					if(datatableConfig.dom.indexOf('S') === -1 && (!table.is('[data-mc-datatable-dom]') || table.attr('data-mc-datatable-dom') == '')){
						datatableConfig.dom += 'S';
					}
*/
				}

				table.DataTable(datatableConfig)

				var searchbar = table
					.closest('.dataTables_wrapper')
					.find('.dataTables_filter')
					.addClass('mc-searchbar')
				searchbar
					.append(
						$('input', searchbar).attr(
							'placeholder',
							mc.fn.getLznString('Search')
						)
					)
					.find('label')
					.remove()

				mc.fn.makeSearchBar(searchbar)

				var searchDelay = null

				$('.mc-searchbar-input', searchbar)
					.off('keyup.DT input.DT')
					.on('keyup', function(e) {
						e.preventDefault()

						var searchValue = $(this).val(),
							timeout = e.keyCode == '13' ? 0 : 350

						clearTimeout(searchDelay)

						searchDelay = setTimeout(function() {
							table
								.DataTable()
								.search(searchValue)
								.draw()
						}, timeout)
					})

				if (table.is('[data-mc-datatable-update-form]')) {
					var updateForm = $(table.data('mc-datatable-update-form')),
						updateInputs = $(':input[name]', updateForm),
						fields = []

					updateInputs
						.filter(':not([type="hidden"])')
						.each(function() {
							var input = $(this),
								readonly = input.is('[readonly]'),
								fieldConfig = {
									label:
										(input.attr('title') ||
											input.attr('name')) + ': ',
									name: input.attr('name'),
								}

							if (readonly) {
								fieldConfig.type = 'readonly'
							} else {
								switch (input[0].tagName.toLowerCase()) {
									case 'select':
										fieldConfig.type = 'select'
										fieldConfig.ipOpts = []
										$('option', input).each(function() {
											fieldConfig.ipOpts.push({
												label: $(this).text(),
												value: $(this).attr('value'),
											})
										})
										break

									case 'textarea':
										fieldConfig.type = 'textarea'
										break

									case 'input':
										fieldConfig.type = input.attr('type')
										break
								}
							}

							fields.push(fieldConfig)
						})

					table.data(
						'mc-datatable-editor',
						new $.fn.dataTable.Editor({
							ajax: {
								url: updateForm.attr('action'),
							},
							table: table,
							fields: fields,
						})
					)

					table
						.data('mc-datatable-editor')
						.on('preSubmit', function(e, formData, action) {
							var data = formData.data

							delete formData.action
							delete formData.data
							delete formData.id

							updateInputs
								.filter('[type="hidden"]')
								.each(function() {
									formData[$(this).attr('name')] = $(
										this
									).val()
								})

							$.each(data, function(key, val) {
								formData[key] = val
							})
						})

					table.on('click', '.mc-datatable-row-edit-btn', function(
						e
					) {
						e.preventDefault()

						table
							.data('mc-datatable-editor')
							.title(mc.fn.getLznString('Edit record'))
							.buttons({
								label: mc.fn.getLznString('Update'),
								fn: function() {
									table.data('mc-datatable-editor').submit()
								},
							})
							.edit($(this).closest('tr'))
					})

					// Activate an inline edit on click of a table cell
					table.on(
						'click',
						'tbody td.mc-datatable-column-editable',
						function(e) {
							table.data('mc-datatable-editor').inline(this, {
								submitOnBlur: true,
							})
						}
					)
				}

				if (table.is('[data-mc-datatable-delete-form]')) {
					var deleteForm = $(
							table.data('mc-datatable-delete-form')
						).ajaxForm({
							datatype: 'json',
							beforeSubmit: function(data, form, options) {
								table.trigger(
									'mc-datatable-delete-before-submit',
									{ data: data, form: form, options: options }
								)
							},
							success: function(response, status, xhr, form) {
								if (response.success)
									table
										.data('mc-datatable-delete-row')
										.remove()
										.draw()
								table.trigger('mc-datatable-delete-success', {
									response: response,
									status: status,
									xhr: xhr,
									form: form,
								})
							},
							error: function(xhr, status, error) {
								table.trigger('mc-datatable-delete-error', {
									xhr: xhr,
									status: status,
									error: error,
								})
							},
						}),
						deleteInputs = $(':input[name]', deleteForm)

					table.on('click', '.mc-datatable-row-delete-btn', function(
						e
					) {
						e.preventDefault()

						var row = table.DataTable().row($(this).closest('tr')),
							rowData = row.data()

						if (
							confirm(mc.fn.getLznString('confirm-delete-item'))
						) {
							deleteInputs
								.filter(
									':not([type="readonly"],[name="userKey"],[name="accountKey"])'
								)
								.each(function() {
									var propName = $(this).attr('name')
									if (rowData.hasOwnProperty(propName))
										$(this).val(rowData[propName])
								})
							table.data('mc-datatable-delete-row', row)
							deleteForm.trigger('submit')
						}
					})
				}
			})
		},
		makeInfiniteScroll: function(els) {
			els.each(function() {
				var scrollEl = $(this),
					formEl = $($(this).attr('data-mc-infinite-scroll-form')),
					itemsEl = $(
						$(this).attr('data-mc-infinite-scroll-items-wrap')
					),
					wrapEl = $(
						scrollEl.is('[data-mc-infinite-scroll-wrap]')
							? scrollEl.attr('data-mc-infinite-scroll-wrap')
							: window
					)

				formEl
					.ajaxForm({
						datatype: 'json',
						beforeSend: function(xhr, settings) {
							if (typeof formEl.data('xhr') !== 'undefined')
								formEl.data('xhr').abort()
							formEl.data('xhr', xhr)
						},
						beforeSubmit: function(data, form, options) {
							var page = parseInt($('[name="p"]', form).val(), 10)
							if (
								page > 1 &&
								scrollEl.is('.mc-infinite-scroll-loading')
							)
								return false
							$('.mc-infinite-scroll-loader', scrollEl).remove()
							scrollEl
								.removeClass('mc-infinite-scroll-nomore')
								.addClass('mc-infinite-scroll-loading')
								.append(
									$(
										'#mc-tmpl-infinite-scroll-loading-indicator'
									).tmpl({})
								)
								.trigger('mc-infinite-scroll-before-submit', {
									data: data,
									form: form,
									options: options,
								})

							if (page == 1) {
								scrollEl.removeClass('mc-infinite-scroll-init')
								itemsEl.empty()
							}

							var searchbar = form.find('.mc-searchbar')
							if (searchbar.length) {
								var q = searchbar.find('[name="q"]'),
									clear = searchbar.find('.mc-searchbar-icon')
								if (q.length && clear.length)
									searchbar[
										q.val() == ''
											? 'addClass'
											: 'removeClass'
									]('mc-searchbar-is-empty')
							}
						},
						success: function(response, status, xhr, form) {
							if (
								!response.success ||
								(response.success &&
									(response.page == response.totalPages ||
										response.totalPages == 0))
							)
								scrollEl.addClass('mc-infinite-scroll-nomore')
							$('.mc-infinite-scroll-loader', scrollEl).remove()
							scrollEl
								.addClass('mc-infinite-scroll-init')
								.removeClass('mc-infinite-scroll-loading')
								.data('mc-infinite-scroll-response', response)
								.trigger('mc-infinite-scroll-success', {
									response: response,
									status: status,
									xhr: xhr,
									form: form,
								})

							mc.fn.prepareTooltips()
							wrapEl.trigger('scroll')
						},
						error: function(xhr, status, error) {
							$('.mc-infinite-scroll-loader', scrollEl).remove()
							scrollEl
								.removeClass('mc-infinite-scroll-loading')
								.trigger('mc-infinite-scroll-error', {
									xhr: xhr,
									status: status,
									error: error,
									form: formEl,
								})
						},
					})
					.find('[name="q"]')
					.on('keyup change', function(e) {
						//e.preventDefault();
						var val = $(this).val()
						if (val == $(this).data('mc-prev-val')) return
						$(this).data('mc-prev-val', val)
						scrollEl.removeClass('mc-infinite-scroll-nomore')
						$('[name="p"]', formEl).val(1)
						$(this)
							.closest('.mc-searchbar')
							[val == '' ? 'addClass' : 'removeClass'](
								'mc-searchbar-is-empty'
							)
						mc.fn.formKetchup(e)
					})
					.end()
					.find('[name="p"]')
					.val(1)
					.end()
					.find('[name="company_wide"]')
					.on('change', function(e) {
						mc.fn.formKetchup(e)
					})

				wrapEl.on('scroll', function() {
					requestAnimationFrame(function() {
						if (
							scrollEl.is(
								'.mc-infinite-scroll-init:not(.mc-infinite-scroll-loading, .mc-infinite-scroll-nomore):visible'
							)
						) {
							var remainingScroll =
									wrapEl.prop('scrollHeight') -
									wrapEl.outerHeight() -
									wrapEl.scrollTop(),
								distance =
									parseInt(
										scrollEl.data(
											'mc-infinite-scroll-distance'
										),
										10
									) || 1500
							if (remainingScroll < distance) {
								var pageInput = $('[name="p"]', formEl)
								pageInput.val(parseInt(pageInput.val(), 10) + 1)
								formEl.trigger('submit')
							}
						}
					})
				})
			})
		},
		makeAutoComplete: function(els) {
			els.each(function(index) {
				var input = $(this),
					form = $(input.attr('data-mc-autocomplete-form')),
					minLength = parseInt(
						input.attr('data-mc-autocomplete-minlength') || 2,
						10
					),
					multiple =
						input.attr('data-mc-autocomplete-multiple') == 'y',
					renderTemplate = input.is(
						'[data-mc-autocomplete-render-template]'
					)
						? input.attr('data-mc-autocomplete-render-template')
						: ''

				$(this)
					.bind('keydown', function(event) {
						if (
							event.keyCode === $.ui.keyCode.TAB &&
							$(this).data('ui-autocomplete').menu.active
						)
							event.preventDefault()
					})
					.autocomplete({
						source: function(request, response) {
							var term = multiple
								? request.term.split(/,\s*/).pop()
								: request.term

							$.ajax({
								url: form.attr('action'),
								dataType: 'json',
								data:
									form.serialize() +
									'&' +
									input.attr('name') +
									'=' +
									encodeURIComponent(term),
								success: function(data) {
									$(input)
										.parent('div')
										.removeClass(
											'mc-autocomplete-searching'
										)
									if (data.items.length == 0) {
										$(input)
											.parent('div')
											.addClass(
												'mc-autocomplete-no-results'
											)
									}
									input.trigger(
										'mc-autocomplete-source-success',
										{ data: data, response: response }
									)
								},
							})
						},
						search: function() {
							$(input)
								.parent('div')
								.removeClass('mc-autocomplete-no-results')
							if (input.val().length >= minLength) {
								$(input)
									.parent('div')
									.addClass('mc-autocomplete-searching')
							}
							if (
								(multiple
									? this.value.split(/,\s*/).pop()
									: this.value
								).length < minLength
							)
								return false
						},
						focus: function() {
							return false
						},
						select: function(event, ui) {
							//if(multiple){
							//  var terms = this.value.split(/,\s*/);
							//  terms.pop();
							//  terms.push(ui.item.value);
							//  terms.push("");
							//  this.value = terms.join(", ");
							//  return false;
							//}
							input.trigger('mc-autocomplete-select', {
								item: ui.item,
							})
							return false
						},
					})
				if (renderTemplate != '') {
					$(this).data('ui-autocomplete')._renderItem = function(
						ul,
						item
					) {
						var li = $('<li>'),
							a = $('<a>').appendTo(li)
						$(renderTemplate)
							.tmpl(item)
							.appendTo(a)
						return li.appendTo(ul)
					}
				}
			})
		},
		makeDateInput: function(els) {
			els.each(function(index) {
				if (!Modernizr.touch || !Modernizr.inputtypes.date) {
					if ($(this).attr('type') != 'text') $(this)[0].type = 'text'
					$(this).datepicker()
				} else {
					if (
						Modernizr.inputtypes.date &&
						$(this).attr('type') != 'date'
					)
						$(this)[0].type = 'date'
				}
			})
		},
		makeSearchBar: function(els) {
			els.each(function() {
				var searchbar = $(this)
				var input = searchbar
					.append('<label class="mc-searchbar-label" />')
					.append(
						$('<span class="mc-searchbar-clear" />').on(
							'click',
							function(e) {
								e.preventDefault()
								$(this)
									.siblings('.mc-searchbar-input')
									.val('')
									.trigger('change')
									.focus()
							}
						)
					)
					.find('[type="search"]')
					.addClass('mc-searchbar-input')
					.on('keyup change', function(e) {
						searchbar[
							$(this).val() == '' ? 'addClass' : 'removeClass'
						]('mc-searchbar-is-empty')
					})

				searchbar[input.val() == '' ? 'addClass' : 'removeClass'](
					'mc-searchbar-is-empty'
				)
			})
		},
		getBasicAssetType: function(ext) {
			switch (ext.toLowerCase()) {
				case 'audio':
				case 'aif':
				case 'aiff':
				case 'mp3':
				case 'm4a':
				case 'wav':
				case 'wma':
					return 'audio'

				case 'video':
				case 'avi':
				case 'flv':
				case 'mov':
				case 'mp4':
				case 'm4v':
				case 'mpg':
				case 'mpeg':
				case 'wmv':
					return 'video'

				case 'image':
				case 'gif':
				case 'jpeg':
				case 'jpg':
				case 'png':
				case 'tif':
				case 'tiff':
					return 'image'

				default:
					return 'attachment'
			}
		},
		prepareTooltips: function() {
			if (!mc.browser.ismobiledevice) {
				$('.mc-tooltip:not(.mc-tooltip-init)')
					.tipTip(mc.settings.tiptip)
					.addClass('mc-tooltip-init')
			}
		},
		formKetchup: function(e) {
			var form = $(typeof e.target == 'undefined' ? e : e.target).closest(
				'form'
			)
			var formDOM = form.get(0)
			clearTimeout($.data(formDOM, 'timer'))
			if (e.keyCode == 13) return // enter key will already be submitting the form
			$.data(
				formDOM,
				'timer',
				setTimeout(function() {
					form.trigger('submit')
				}, 500)
			)
		},
		publishAsset: function(options) {
			var pubHref =
				'/publish/asset/' + options.pubSlug + '/' + options.assetKey

			switch (options.pubSlug) {
				case 'pinterest':
				case 'prersecard':
				case 'chalk-digital':
					window.open(pubHref, '_blank')
					break

				case 'sms':
					switch (mc.fn.getBasicAssetType(options.assetType)) {
						case 'image':
							mc.fn.showOverlay(pubHref)
							break

						default:
							if (mc.isApp) {
								$.get(pubHref + '?getRedirectURL=1').done(
									function(data) {
										window.location = data
									}
								)
							} else {
								window.open(pubHref, '_blank')
							}
							break
					}
					break

				case 'ecard':
					var d = $('<div></div>').dialog({
						modal: true,
						draggable: false,
						resizable: false,
						autoOpen: true,
						title:
							$(this).attr('title') ||
							mc.fn.getLznString('Send eCard'),
						dialogClass: 'dialog-iframe',
						height: $(window).height() - 100,
						width: $(window).width() - 100,
						close: function() {
							$(this)
								.dialog('destroy')
								.remove()
						},
						open: function() {
							$(this).html(
								'<iframe src="' +
									pubHref +
									'" style="width:100%;height:100%;"></iframe>'
							)
						},
					})
					break

				default:
					mc.fn.showOverlay(pubHref)
					if (
						$.inArray(options.pubSlug.toLowerCase(), [
							'download',
							'download-image',
						]) != -1
					) {
						$('body').removeClass('mc-overlay-changing')
					}
					break
			}
		},
		processMoments: function(container) {
			container =
				typeof container !== 'undefined' ? container : $(document)
			container
				.find('.mc-moment-from-now,.mc-moment-format')
				.andSelf()
				.each(function() {
					if ($(this).is('.mc-moment-from-now')) {
						$(this).text(
							moment
								.tz($(this).data('mcMomentValue'), 'UTC')
								.fromNow()
						)
					} else if ($(this).is('.mc-moment-format')) {
						$(this).text(
							moment
								.tz($(this).data('mcMomentValue'), 'UTC')
								.format(
									typeof $(this).data('mcMomentFormat') !==
										'undefined'
										? $(this).data('mcMomentFormat')
										: 'L'
								)
						)
					}
				})
		},
		publishCampaign: function(options) {
      var currentUrl = new Url()
			var pubHref = new Url(`/publish/campaign/${options.pubSlug}/${options.campaignKey}`)
      if (currentUrl.query.jwt) {
        pubHref.query.jwt = currentUrl.query.jwt
      }
			var	lockedAfterPublish = false,
				refreshAfterPublish = false,
				share = function() {
					if (refreshAfterPublish) {
						var refreshUrl = new Url(
							'/detail/campaign/' +
								options.campaignKey +
								'?published&pubSlug=' +
								options.pubSlug
						)
						if (currentUrl.query.mcoverlay == '1') {
							refreshUrl.query.mcoverlay = '1'
            }
            if (currentUrl.query.jwt) {
              refreshUrl.query.jwt = currentUrl.query.jwt
            }
					}

					switch (options.pubSlug.toLowerCase()) {
						case 'pinterest':
						case 'prersecard':
						case 'chalk-digital':
							window.open(pubHref, '_blank')
							if (refreshAfterPublish)
								setTimeout(function() {
									window.location.href = refreshUrl
								}, 500)
							break

						case 'ecard':
							var d = $('<div></div>').dialog({
								modal: true,
								draggable: false,
								resizable: false,
								autoOpen: true,
								title:
									$(this).attr('title') ||
									mc.fn.getLznString('Send eCard'),
								dialogClass: 'dialog-iframe',
								height: $(window).height() - 100,
								width: $(window).width() - 100,
								close: function() {
									$(this)
										.dialog('destroy')
										.remove()
									if (refreshAfterPublish)
										window.location.href = refreshUrl
								},
								open: function() {
									$(this).html(
										'<iframe src="' +
											pubHref +
											'" style="width:100%;height:100%;"></iframe>'
									)
								},
							})
							break

						case 'epub':
						case 'pdf':
							$.ajax({ url: pubHref })
							$(
								'<div><div class="mc-progress-bar"><div class="mc-progress-bar-label"></div></div></div>'
							).dialog({
								modal: true,
								draggable: false,
								resizable: false,
								autoOpen: true,
								title:
									$(this).attr('title') ||
									mc.fn.getLznString('Generating'),
								dialogClass: 'mc-progress',
								close: function() {
									$(this)
										.dialog('destroy')
										.remove()
									mc.fn.checkPubProgress = function() {}
								},
								open: function() {
									var d = $(this)
									mc.fn.checkPubProgress = function() {
                    var url = new Url(pubHref.toString())
                    url.query.getProgress = 1
                    url.query._ = $.now()
                    if (currentUrl.query.jwt) {
                      url.query.jwt = currentUrl.query.jwt
                    }
										$.ajax({
											type: 'GET',
											url: url,
											dataType: 'json',
										})
											.done(function(data) {
												if (data.success) {
													$(
														'.mc-progress-bar',
														d
													).progressbar({
														value: !data.progress
															.step
															? false
															: (parseInt(
																	data
																		.progress
																		.step,
																	10
															  ) /
																	parseInt(
																		data
																			.progress
																			.steps,
																		10
																	)) *
															  100,
													})
													$(
														'.mc-progress-bar-label',
														d
													).text(
														!data.progress.step
															? data.progress
																	.status
															: 'Step ' +
																	data
																		.progress
																		.step +
																	' of ' +
																	data
																		.progress
																		.steps +
																	': ' +
																	data
																		.progress
																		.status
													)
												}
											})
											.fail(function() {
												$(
													'.mc-progress-bar',
													d
												).progressbar({ value: false })
												$(
													'.mc-progress-bar-label',
													d
												).html(
													mc.fn.getLznString(
														'There was an error. Please try again.'
													)
												)
											})
											.always(function(data) {
												if (
													data.success &&
													data.progress &&
													data.progress.step &&
													data.progress.steps
												) {
													var step = parseInt(
															data.progress.step,
															10
														),
														steps = parseInt(
															data.progress.steps,
															10
														)
													if (
														step > 0 &&
														step == steps
													) {
														window.location.href =
															data.downloadurl
														d.dialog('close')
														return
													}
												}
												setTimeout(function() {
													mc.fn.checkPubProgress()
												}, 1000)
											})
									}
									mc.fn.checkPubProgress()
								},
							})
							break

						case 'pod':
							$.ajax({ url: pubHref })
							$(
								'<div><div class="mc-progress-bar"><div class="mc-progress-bar-label"></div></div></div>'
							).dialog({
								modal: true,
								draggable: false,
								resizable: false,
								autoOpen: true,
								title:
									$(this).attr('title') ||
									mc.fn.getLznString('Generating PDF'),
								dialogClass: 'mc-progress',
								close: function() {
									$(this)
										.dialog('destroy')
										.remove()
									mc.fn.checkPubProgress = function() {}
								},
								open: function() {
									var d = $(this)
									mc.fn.checkPubProgress = function() {
										var url = new Url(pubHref.toString())
                    url.query.getProgress = 1
                    url.query._= $.now()
                    if (currentUrl.query.jwt) {
                      url.query.jwt = currentUrl.query.jwt
                    }
										if (
											typeof d.data(
												'mc-publish-event'
											) !== 'undefined'
										) {
                      url.query.eventID= d.data('mc-publish-event').eventID
										}

										$.ajax({
											type: 'GET',
											url: url,
											dataType: 'json',
										})
											.done(function(data) {
												if (data.success) {
													$(
														'.mc-progress-bar',
														d
													).progressbar({
														value: !data.progress
															.step
															? false
															: (parseInt(
																	data
																		.progress
																		.step,
																	10
															  ) /
																	parseInt(
																		data
																			.progress
																			.steps,
																		10
																	)) *
															  100,
													})
													$(
														'.mc-progress-bar-label',
														d
													).text(
														!data.progress.step
															? data.progress
																	.status
															: 'Step ' +
																	data
																		.progress
																		.step +
																	' of ' +
																	data
																		.progress
																		.steps +
																	': ' +
																	data
																		.progress
																		.status
													)
												}
											})
											.fail(function() {
												$(
													'.mc-progress-bar',
													d
												).progressbar({ value: false })
												$(
													'.mc-progress-bar-label',
													d
												).html(
													mc.fn.getLznString(
														'There was a problem creating this PDF.  Please try again.'
													)
												)
											})
											.always(function(data) {
												if (
													data.success &&
													data.progress &&
													data.event
												) {
													if (
														data.progress.step >
															0 &&
														data.progress.step ==
															data.progress.steps
													) {
														d.dialog('close')
                            var now = $.now()
														var urlProof = new Url(`/media/campaign/${options.campaignKey}/pod/proof.pdf?eventID=${data.event.eventID}&_=${now}`)
														var urlPreview = new Url(`/media/campaign/${options.campaignKey}/pod/proof.pdf?eventID=${data.event.eventID}&inline=1&_=${now}`)
														var urlPOD = new Url(pubHref.toString())
                            urlPOD.query.redirectPod = 1
                            urlPOD.query.eventID=data.event.eventID
                            urlPOD.query._ = now

                            if (currentUrl.query.jwt) {
                              urlProof.query.jwt = currentUrl.query.jwt
                              urlPreview.query.jwt = currentUrl.query.jwt
                              urlPOD.query.jwt = currentUrl.query.jwt
                            }
														$(
															'<div><div class="mc-pod-disclaimer"><p>' +
																mc.fn.getLznString(
																	'pod-final-proof-alert'
																) +
																'</p></div></div>'
														).dialog({
															modal: true,
															draggable: false,
															resizable: false,
															autoOpen: true,
															dialogClass:
																'mc-pod',
															height:
																$(
																	window
																).height() -
																100,
															width:
																$(
																	window
																).width() - 100,
															title: mc.fn.getLznString(
																'Print Approval Process'
															),
															close: function() {
																$(this)
																	.dialog(
																		'destroy'
																	)
																	.remove()
															},
															open: function() {
																var previewHeight =
																	$(
																		this
																	).height() -
																	$(this)
																		.find(
																			'.mc-pod-disclaimer'
																		)
																		.height() -
																	20
																if (
																	$(
																		'body'
																	).is(
																		'.browser-browser-firefox'
																	)
																) {
																	$(
																		this
																	).append(
																		'<iframe src="' +
																			urlPreview +
																			'" style="width:100%;height:' +
																			previewHeight +
																			'px;"></iframe>'
																	)
																} else {
																	$(
																		this
																	).append(
																		'<div id="mc-pod-preview" style="height:' +
																			previewHeight +
																			'px;"><p>' +
																			mc.fn.getLznString(
																				'browser-no-support-pdf-display-msg',
																				{
																					linkAttributes:
																						'href="' +
																						urlProof +
																						'" class="mc-btn"',
																				}
																			) +
																			'</p></div>'
																	)
																	PDFObject.embed(
																		urlPreview,
																		'#mc-pod-preview'
																	)
																}
															},
															buttons: [
																{
																	text: mc.fn.getLznString(
																		'Download PDF'
																	),
																	click: function() {
																		window.location.href = urlProof
																	},
																	class:
																		'mc-ui-button-showoff',
																},
																{
																	text: mc.fn.getLznString(
																		'Cancel and Close'
																	),
																	click: function() {
																		$(
																			this
																		).dialog(
																			'close'
																		)
																	},
																},
																{
																	text: mc.fn.getLznString(
																		'Approve and Continue'
																	),
																	click: function() {
																		$(this)
																			.dialog(
																				'close'
																			)
																			.remove()
																		$(
																			'<div><p>' +
																				mc.fn.getLznString(
																					'pod-leave-media-center-msg'
																				) +
																				'</p></div>'
																		).dialog(
																			{
																				modal: true,
																				draggable: false,
																				resizable: false,
																				autoOpen: true,
																				title: mc.fn.getLznString(
																					'Notice'
																				),
																				close: function() {
																					$(
																						this
																					)
																						.dialog(
																							'destroy'
																						)
																						.remove()
																				},
																				buttons: [
																					{
																						text: mc.fn.getLznString(
																							'Cancel'
																						),
																						click: function() {
																							$(
																								this
																							).dialog(
																								'close'
																							)
																						},
																					},
																					{
																						text: mc.fn.getLznString(
																							'Continue'
																						),
																						click: function() {
																							$(
																								this
																							).dialog(
																								'close'
																							)
																							window.open(
																								urlPOD.toString()
																							)
																						},
																					},
																				],
																			}
																		)
																	},
																},
															],
														})
														return
													}
												}
												setTimeout(function() {
													mc.fn.checkPubProgress()
												}, 500)
											})
									}
									mc.fn.checkPubProgress()
								},
							})
							break

						default:
						if (currentUrl.query.headless == '1') {
              const url = new URL(pubHref);
              url.searchParams.set('headless', '1')
              window.location.href = url.toString()
            }	else if (mc.user.userTypeIsHost) {
								window.location.href = pubHref
							} else {
								mc.fn.showOverlay(pubHref)
								if (
									$.inArray(options.pubSlug.toLowerCase(), [
										'download',
										'download-image',
									]) != -1
								) {
									$('body').removeClass('mc-overlay-changing')
								}
							}
							break
					}
				}

			$.blockUI({ message: mc.fn.getLznString('Loading') })

			var ajaxResponse = {}

			$.ajax({
				type: 'POST',
				url: '/api/beta/getCampaign',
				dataType: 'json',
				data: {
					campaignKey: options.campaignKey,
					userKey: mc.user.userKey,
					accountKey: mc.account.accountKey,
				},
				async: false,
			})
				.done(function(data) {
					ajaxResponse = data
				})
				.fail(function() {
					mc.alerts.warn(
						mc.fn.getLznString('An unknown error occurred')
					)
				})
				.always(function() {
					$.unblockUI()
				})

			if (ajaxResponse.success) {
				lockedAfterPublish =
					$.inArray(ajaxResponse.campaign.campaignTypeSlug, [
						'ecard',
						'newsletter',
					]) != -1 &&
					$.inArray(options.pubSlug, [
						'facebook',
						'twitter',
						'pinterest',
						'linkedin',
						'prersecard',
						'links',
						'embed',
						'chalk-digital',
					]) != -1
				refreshAfterPublish =
					lockedAfterPublish && $('body').is('.campaign-view-edit')
				if (
					ajaxResponse.campaign.campaignShareStatus.toLowerCase() ==
					'incomplete'
				) {
					if (mc.oc.liveEditorForm)
						mc.oc.liveEditorForm.trigger('submit')
					var errormsg = mc.fn.getLznString(
						'This campaign is not ready to be published.'
					)
					if (
						ajaxResponse.campaign.campaignInvalidInputs &&
						ajaxResponse.campaign.campaignInvalidInputs.length
					) {
						errormsg = mc.fn.getLznString(
							'The following issues must be fixed before publishing:'
						)
						$.each(
							ajaxResponse.campaign.campaignInvalidInputs,
							function(index, value) {
								errormsg +=
									'\n' + value.label + ': ' + value.errormsg
							}
						)
					}
					mc.alerts.warn(errormsg)
				} else if (
					ajaxResponse.campaign.campaignShareStatus.toLowerCase() ==
						'good2go' &&
					lockedAfterPublish
				) {
					$('<div></div>')
						.html(
							'<p>' +
								mc.fn.getLznString(
									'sharing-campaign-uneditable-warning'
								) +
								'</p>'
						)
						.dialog({
							modal: true,
							draggable: false,
							resizable: false,
							autoOpen: true,
							title: mc.fn.getLznString('Sharing Confirmation'),
							close: function() {
								$(this)
									.dialog('destroy')
									.remove()
							},
							buttons: [
								{
									text: mc.fn.getLznString('Cancel'),
									click: function() {
										$(this).dialog('close')
									},
								},
								{
									text: mc.fn.getLznString('Share'),
									click: function() {
										share()
										$(this).dialog('close')
									},
								},
							],
						})
				} else {
					share()
				}
			} else {
				if (
					ajaxResponse &&
					ajaxResponse.error &&
					ajaxResponse.error.message
				) {
					mc.alerts.warn(
						mc.fn.getLznString('Error sharing: [[error]]', {
							error: ajaxResponse.error.message,
						})
					)
				} else {
					mc.alerts.warn(
						mc.fn.getLznString('An unknown error occurred')
					)
				}
			}
		},
		dialogLinkHandler: function(e) {
			e.preventDefault()
			var href = $(this).is('button')
				? $(this).attr('data-dialog-target')
				: $(this).attr('href')
			if (href.substr(0, 7) == '#dialog') {
				if (href == '#dialog-builder') {
					if (mc.doc.docPublished == 'y') {
						$('#alert-editPublished').dialog('open')
						return
					} else if (mc.doc.docPublic == 'y') {
						$('#alert-editShared').dialog('open')
						return
					}
				}
				$(href).dialog('open')
			} else {
				var ext = href.substr(href.lastIndexOf('.') + 1).toLowerCase()
				if ($.browser.msie && ext != 'flv' && href != '/profile/') {
					window.open(href, '_blank')
				} else {
					var dButtons = $(this).is('.action-share')
						? [
								{
									text: mc.fn.getLznString('Ok'),
									click: function() {
										$(this).dialog('close')
									},
								},
						  ]
						: []

					var h =
						$(this).attr('data-dialog-height') ||
						$(window).height() - 100
					var w =
						$(this).attr('data-dialog-width') ||
						$(window).width() - 100

					var isVideo = mc.fn.getBasicAssetType(ext) == 'video'
					if (isVideo) {
						if ((w / 4) * 3 > h) {
							Math.round((w = (h / 3) * 4))
						} else {
							Math.round((h = (w / 4) * 3))
						}
					}

					var d = $('<div></div>').dialog({
						modal: true,
						draggable: false,
						resizable: false,
						autoOpen: true,
						buttons: dButtons,
						title: $(this).attr('title') || $(this).text(),
						dialogClass: isVideo
							? 'mc-dialog-quickview-asset mc-dialog-enable-close'
							: 'dialog-iframe',
						height: h,
						width: w,
						close: function() {
							$(this)
								.dialog('destroy')
								.remove()
						},
						open: function() {
							if (ext == 'mp4') {
								var id = 'dialog-videoplayer-' + $.now()
								var vidID = id + '-video-' + $.now()
								$(this).html(
									'<div id="' +
										id +
										'" style="display:block;width:100%;height:100%;margin:0 auto;"><video id="' +
										vidID +
										'" class="video-js vjs-default-skin" width="100%" height="100%" preload controls autoplay><source src="' +
										href +
										'" type="video/mp4" /></video></div>'
								)
								videojs(vidID)
							} else {
								$(this).html(
									'<iframe src="' +
										href +
										'" style="width:100%;height:100%;"></iframe>'
								)
							}
						},
					})
				}
			}
			$(href).dialog('open')
			mc.fn.cacheDialogs()
		},
		prepareContextMenus: function() {
			$.contextMenu('destroy')
			$.contextMenu({
				selector: 'a[href|="#cmenu"]',
				trigger: 'left',
				build: function(trigger) {
					var menuEl = $(trigger.attr('href'))
					return {
						className: 'cmenu-id-' + menuEl.attr('id'),
						items: $.contextMenu.fromMenu(menuEl),
						events: {
							show: function(opt) {
								$(this).addClass('cmenu-active')
							},
							hide: function(opt) {
								$(this).removeClass('cmenu-active')
							},
						},
						position: function(menu, x, y) {
							menu['$menu']
								.css({ opacity: 0, 'z-index': 10000 })
								.position({
									my: 'center top',
									at: 'center bottom',
									of: menu['$trigger'],
									offset: '0 -5',
									collision: 'fit flip',
								})
								.animate(
									{
										opacity: 1,
									},
									100,
									'easeOutExpo'
								)
						},
					}
				},
			})
		},
		cacheDialogs: function() {
			setTimeout(function() {
				mc.dialogs = $('body > div.ui-dialog > .ui-dialog-content')
				mc.fn.repositionDialogs()
			}, 500)
		},
		repositionDialogs: function() {
			if (mc.dialogs && mc.dialogs.length) {
				var maxHeight = $(window).height() - 50
				mc.dialogs.each(function() {
					var d = $(this)
					if (
						d.is('.ui-dialog-content') &&
						d.dialog('isOpen') &&
						d.closest('.ui-dialog').outerHeight() < maxHeight
					)
						d.dialog(
							'option',
							'position',
							d.dialog('option', 'position')
						)
				})
			}
		},
		processSearchResults: function(items, options) {
			options = $.extend(true, { lazyLoadContainer: window }, options)

			var target = $(options.target),
				lazyLoadContainer = $(options.lazyLoadContainer)

			if (items.length) {
				if (items[0].assetID) {
					$.each(items, function(i, item) {
						var tmpl = '#mc-tmpl-search-result-asset-file'
						item.assetGenericType = mc.fn.getBasicAssetType(
							item.assetType
						)
						switch (item.assetGenericType) {
							case 'audio':
								tmpl = '#mc-tmpl-search-result-asset-audio'
								break

							case 'image':
								tmpl = '#mc-tmpl-search-result-asset-image'
								break

							case 'video':
								tmpl = '#mc-tmpl-search-result-asset-video'
								break
						}
						$(tmpl)
							.tmpl(item)
							.appendTo(target)
					})
				} else if (items[0].campaignID) {
					$('#mc-tmpl-search-result-campaign')
						.tmpl(items)
						.appendTo(target)
				} else if (items[0].templateID) {
					$('#mc-tmpl-search-result-template')
						.tmpl(items)
						.appendTo(target)
				} else if (items[0].docID) {
					$('#mc-tmpl-search-result-doc')
						.tmpl(items)
						.appendTo(target)
				}
			}

			$('img.mc-lazy', target).lazyload({
				threshold: 300,
				placeholder: '/assets/images/shim.png',
				container: lazyLoadContainer,
				load: function() {
					var img = $(this)

					img.closest('.image').css({ 'background-color': '' })
					if ($('body').is('.mc-screen-smart,.mc-screen-mobile')) {
						var container = $(this).closest('.image')

						img.css({ width: '100%', height: 'auto' })
						if (img.height() < container.height())
							img.css({ width: 'auto', height: '100%' })
						img.css({
							left: (container.width() - img.width()) / 2 + 'px',
							top: (container.height() - img.height()) / 2 + 'px',
						})
					} else {
						img.css({
							width: '100%',
							height: 'auto',
							left: 'auto',
							top: 'auto',
						})
					}
				},
			})

			if ($('body').is('.mc-screen-smart,.mc-screen-mobile')) {
				if (target.is('.mc-wookmark')) {
					$('.search-result-item', target).css({
						position: 'relative',
						top: 'auto',
						left: 'auto',
					})
					target.css({ height: 'auto' }).removeClass('mc-wookmark')
				}
			} else {
				target.addClass('mc-wookmark')

				var wookmarkEl = $('.search-result-item', target),
					wookmarkConfig = {
						container: target,
						align: 'center',
						autoResize: true,
						comparator: null,
						direction: undefined,
						ignoreInactiveItems: true,
						itemWidth: 0,
						fillEmptySpace: false,
						flexibleWidth: 0,
						offset: 25,
						onLayoutChanged: function() {
							lazyLoadContainer.trigger('scroll')
						},
						outerOffset: 0,
						possibleFilters: [],
						resizeDelay: 50,
						verticalOffset: undefined,
					}

				wookmarkEl.wookmark(wookmarkConfig)
			}

			lazyLoadContainer.trigger('scroll')
		},
		updateUserMeta: function(key, val) {
			var data = {
				userKey: mc.user.userKey,
				accountKey: mc.account.accountKey,
			}
			data[key] = val
			$.ajax({
				type: 'POST',
				url: '/api/beta/updateUserMeta',
				dataType: 'json',
				data: data,
				success: function(data, status) {
					if (data.success) mc.user.userMeta = data.userMeta
				},
			})
		},
		makeEditable: function(selector) {
			$(selector)
				.each(function(index, el) {
					if (!$(this).attr('data-editable-type') != 'el')
						$($(this).attr('data-editable-target')).wrapInner(
							'<span class="editable-value" />'
						)
					$(this)
						.addClass('editable')
						.append(
							'<a class="diacon diacon-edit edit" href="#">' +
								mc.fn.getLznString('Edit') +
								'</a>' +
								'<a class="diacon diacon-save save" href="#">' +
								mc.fn.getLznString('Save') +
								'</a>' +
								'<a class="diacon diacon-save remove" href="#">' +
								mc.fn.getLznString('Cancel') +
								'</a>'
						)
				})
				.find('.edit')
				.click(function(e) {
					e.preventDefault()
					var el = $(this).closest('.editable')
					el.addClass('editable-active')
					if (el.attr('data-editable-type') == 'el') {
						$(el.attr('data-editable-target')).slideDown('slow')
					} else {
						var target = $(
							el.attr('data-editable-target') + ' .editable-value'
						)
						el.data(
							'editable-prev-value',
							target
								.html()
								.replace(/\r\n|\r|\n/g, '')
								.replace(/\<br(\s*\/|)\>/g, '\r\n')
						)
						switch (el.attr('data-editable-type')) {
							case 'textarea':
								target.html(
									'<textarea class="editable-input">' +
										el.data('editable-prev-value') +
										'</textarea>'
								)
								break

							default:
								target.html(
									'<input class="editable-input" value="' +
										el.data('editable-prev-value') +
										'" />'
								)
								break
						}
					}
				})
				.end()
				.find('.save')
				.click(function(e) {
					e.preventDefault()
					var el = $(this).closest('.editable')
					//el.removeClass('editable-active');
					if (el.attr('data-editable-type') == 'el') {
						//$(el.attr('data-editable-target')).slideUp('slow').find('form').trigger('submit');
						$(el.attr('data-editable-target'))
							.find('form')
							.trigger('submit')
					} else {
						el.removeClass('editable-active')
						var target = $(
							el.attr('data-editable-target') + ' .editable-value'
						)
						el.data(
							'editable-prev-value',
							$('.editable-input', target).val()
						)

						var params = {
							userKey: mc.user.userKey,
							accountKey: mc.account.accountKey,
						}

						params[el.attr('data-editable-id-name')] = el.attr(
							'data-editable-id-value'
						)
						params[el.attr('data-editable-field')] = el.data(
							'editable-prev-value'
						)

						$.ajax({
							type: 'POST',
							url: '/api/beta/' + el.attr('data-editable-method'),
							dataType: 'json',
							data: params,
							success: function(response, status) {},
						})

						target.html(
							el
								.data('editable-prev-value')
								.replace(/\r\n|\r|\n/g, '<br />')
						)

						el.trigger('edited', [el.data('editable-prev-value')])
					}
				})
				.end()
				.find('.remove')
				.click(function(e) {
					e.preventDefault()
					var el = $(this).closest('.editable')
					el.removeClass('editable-active')
					if (el.attr('data-editable-type') == 'el') {
						$(el.attr('data-editable-target')).slideUp('slow')
					} else {
						var target = $(
							el.attr('data-editable-target') + ' .editable-value'
						)
						target.html(
							el
								.data('editable-prev-value')
								.replace(/\r\n|\r|\n/g, '<br />')
						)
					}
				})
		},
		doPagination: function(obj) {
			obj.linkContainer.empty()
			if (obj.totalPages > 1) {
				var paginationLinks = []
				var numNextLinks = 2
				var numPrevLinks = 2
				var maxLinks = 5

				if (obj.totalPages < maxLinks) {
					for (i = 1; i <= Math.min(obj.totalPages, maxLinks); i++) {
						paginationLinks.push({ page: i, label: i })
					}
				} else {
					if (obj.page != 1) {
						if (obj.totalPages - obj.page < numNextLinks)
							numPrevLinks +=
								numNextLinks - (obj.totalPages - obj.page)
						for (
							i = Math.min(numPrevLinks, obj.page - 1);
							i > 0;
							i--
						) {
							paginationLinks.push({
								page: obj.page - i,
								label: obj.page - i,
								classes: '',
							})
						}
					}
					paginationLinks.push({
						page: obj.page,
						label: obj.page,
						classes: 'mc-status-active',
					})
					if (obj.page != obj.totalPages) {
						for (
							i = obj.page + 1;
							i <=
								Math.min(
									obj.totalPages,
									obj.page + numNextLinks
								) ||
							(i <= obj.totalPages &&
								paginationLinks.length < maxLinks);
							i++
						) {
							paginationLinks.push({
								page: i,
								label: i,
								classes: '',
							})
						}
					}
				}

				if (obj.page != 1) {
					paginationLinks.unshift({
						page: obj.page - 1,
						label: 'Previous',
						classes: '',
					})
					paginationLinks.unshift({
						page: 1,
						label: 'First',
						classes: '',
					})
				}

				if (obj.page != obj.totalPages) {
					paginationLinks.push({
						page: obj.page + 1,
						label: 'Next',
						classes: '',
					})
					paginationLinks.push({
						page: obj.totalPages,
						label: 'Last',
						classes: '',
					})
				}

				$.tmpl(obj.linkTemplate, paginationLinks).prependTo(
					obj.linkContainer.empty()
				)
			}
		},
		showStep: function(step) {
			$(step)
				.siblings('.step')
				.hide()
				.end()
				.show()
				.closest('.steps')
				.find('.stepNav[href="' + step + '"]')
				.siblings('.stepNav')
				.removeClass('active')
				.end()
				.addClass('active')
		},
		secondsToHMS: function(s) {
			var totalSeconds = parseInt(s, 10),
				hours = Math.floor(totalSeconds / 3600),
				minutes = Math.floor((totalSeconds - hours * 3600) / 60),
				seconds = totalSeconds - hours * 3600 - minutes * 60
			if (minutes < 10) {
				minutes = '0' + minutes
			}
			if (seconds < 10) {
				seconds = '0' + seconds
			}
			return (hours ? hours + ':' : '') + minutes + ':' + seconds
		},
		generatePassword: function(length) {
			var options = 'aeuybdghjmnpqrstvzBDGHJLMNPQRSTVWXZAEUY23456789@#$%',
				password = ''
			length = length || 8
			for (i = 0; i < length; i++) {
				password += options.charAt(
					Math.floor(Math.random() * options.length)
				)
			}
			return password
		},
		startsAsUrl: function(value) {
			return (
				value.startsWith('https://') ||
				value.startsWith('http://') ||
				value.startsWith('//')
			)
		},
		getProportionalValue: function(fixed, dimension1, dimension2) {
			return (
				Math.round(
					(fixed / parseInt(dimension1, 10)) *
						parseInt(dimension2, 10)
				) || fixed
			)
		},
		nl2br: function(str, is_xhtml) {
			var breakTag =
				is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'
			return (str + '').replace(
				/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
				'$1' + breakTag + '$2'
			)
		},
		showContentPreview: function(options) {
			var fancyboxConfig = '',
				fancyboxOptions = {
					padding: 0,
					closeBtn: false,
					helpers: {
						title: null,
						buttons: {
							skipSingle: true,
							tpl:
								'<div id="fancybox-buttons"><ul style="width:100px;"><li><a class="btnPrev" title="Previous" href="javascript:;"></a></li><li><a class="btnNext" title="Next" href="javascript:;"></a></li><li><a class="btnClose" title="Close" href="javascript:;"></a></li></ul></div>',
						},
					},
				},
				imgSize = $('body').is('.mc-screen-mobile, .mc-screen-smart')
					? 'large'
					: 'xl'

			if ('assetKey' in options && options.assetKey) {
				var updated_at =
					'assetUpdatedAt' in options && options.assetUpdatedAt
						? options.assetUpdatedAt.replace(/[^\d]/g, '')
						: ''
				switch (mc.fn.getBasicAssetType(options.assetType)) {
					case 'audio':
						fancyboxConfig = [
							{
								content: $('<div />')
									.append(
										$('#mc-tmpl-audio-player').tmpl({
											id: 'mc-fancybox-audio',
										})
									)
									.html(),
								type: 'html',
								afterShow: function() {
									$('#audio-sourcemc-fancybox-audio').jPlayer(
										{
											ready: function(event) {
												$(this).jPlayer('setMedia', {
													mp3:
														'//' +
														mc.cdnDomain +
														'/media/' +
														options.assetKey +
														'/' +
														updated_at +
														'/audio.mp3',
												})
											},
											cssSelectorAncestor:
												'#audio-interfacemc-fancybox-audio',
											swfPath:
												'//' +
												mc.cdnDomain +
												'/assets/js/jplayer',
											solution: 'flash,html',
										}
									)
								},
								beforeClose: function() {
									$('.fancybox-inner .jp-audio').remove()
								},
							},
						]

						fancyboxOptions.width = 250
						fancyboxOptions.minWidth = 250
						fancyboxOptions.height = 40
						fancyboxOptions.minHeight = 40
						fancyboxOptions.autoResize = false
						break

					case 'video':
						var id = 'mc-fancybox-video',
							assetKey = options.assetKey,
							w = 1280,
							h = 720,
							autoplay = true,
							controls = true

						fancyboxConfig = [
							{
								content:
									'<div class="mc-video-container" id="' +
									id +
									'" style="width:' +
									w +
									'px;height:' +
									h +
									'px;" data-track-engagement="false"></div>',
								type: 'html',
								afterShow: function() {
									var videoWrap = $('#' + id),
										resizeToEl = videoWrap.closest(
											'.fancybox-inner'
										)

									videoWrap.css({
										width: resizeToEl.width(),
										height: resizeToEl.height(),
									})

                  console.log(videoWrap)

									new MCVideoPlayer(videoWrap, {
										controls: controls,
										autoplay: autoplay,
										width: 500,
										height: 500,
										preload: 'auto',
										cssClasses: 'video-js vjs-default-skin',
										assetKey: assetKey,
										posterUrl:
											'//' +
											mc.cdnDomain +
											'/media/' +
											assetKey +
											'/large/' +
											updated_at +
											'/poster.jpg',
									})
								},
								beforeClose: function() {
									$('#' + id).remove()
								},
							},
						]

						fancyboxOptions.width = w
						fancyboxOptions.height = h
						fancyboxOptions.aspectRatio = true
						break

					default:
						fancyboxConfig = [
							{
								href:
									'/media/' +
									options.assetKey +
									'/' +
									updated_at +
									'/' +
									imgSize,
								type: 'image',
							},
						]
						break
				}
			} else if ('campaignKey' in options && options.campaignKey) {
				var updated_at =
					'campaignUpdatedAt' in options && options.campaignUpdatedAt
						? options.campaignUpdatedAt.replace(/[^\d]/g, '')
						: ''
				fancyboxConfig = [
					{
						href:
							'/media/campaign/' +
							options.campaignKey +
							'/' +
							updated_at +
							'/' +
							imgSize,
						type: 'image',
					},
				]
			} else if ('templateKey' in options && options.templateKey) {
				var updated_at =
					'templateUpdatedAt' in options && options.templateUpdatedAt
						? options.templateUpdatedAt.replace(/[^\d]/g, '')
						: ''
				fancyboxConfig = [
					{
						href:
							'/media/campaign/' +
							options.templateKey +
							'/' +
							updated_at +
							'/' +
							imgSize,
						type: 'image',
					},
				]
			} else if ('docKey' in options && options.docKey) {
				var updated_at =
					'docUpdatedAt' in options && options.docUpdatedAt
						? options.docUpdatedAt.replace(/[^\d]/g, '')
						: ''
				fancyboxConfig = [
					{
						href:
							'/media/doc/' +
							options.docKey +
							'/' +
							imgSize +
							'/' +
							updated_at,
						type: 'image',
					},
				]
			}

			if (fancyboxConfig != '') {
				$.fancybox(fancyboxConfig, fancyboxOptions)
			}
		},
		isLoggedIn: function() {
			return (
				typeof Cookies.get('mcAccountKey') != 'undefined' &&
				typeof Cookies.get('mcUserKey') != 'undefined'
			)
		},
		logOut: function() {
			if (mc.fn.isOverlay()) {
				window.parent.fn.logOut()
			} else {
				window.location.href =
					'/logout/' + (mc.user.clientSlug ? mc.user.clientSlug : '')
			}
		},
		isOverlay: function() {
			return self !== top && !mc.isHeadless
		},
		showOverlay: function(href) {
			if (!mc.fn.isLoggedIn()) {
				mc.fn.logOut()
				return false
			}

			var u = new Url(href.toString())
			u.query.mcoverlay = '1'
			$.each(u.query, function(key, value) {
				if (value === null) {
					u.query[key] = 1
				}
			})
			if (mc.fn.isOverlay()) {
				$('body').addClass('mc-overlay-changing')
				self.location.href = u.toString()
			} else {
				$(document).trigger('mc-overlay-opening', href)
				mc.oc.overlayWrap.addClass('mc-status-active')
				mc.oc.overlay
					.empty()
					.append(
						$(
							'<iframe id="mc-overlay-iframe" name="mc-overlay-iframe" width="100%" height="100%" frameborder="0" marginwidth="0" marginheight="0" allowtransparency="true" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>'
						).attr('src', u.toString())
					)

				var focusOverlayWhenReady = function() {
					var iframe = $('#mc-overlay-iframe')[0],
						doc =
							iframe.contentDocument ||
							iframe.contentWindow.document
					if (doc.readyState == 'complete') {
						iframe.contentWindow.focus()
					} else {
						setTimeout(focusOverlayWhenReady, 100)
					}
				}

				setTimeout(focusOverlayWhenReady, 10)
				$(document).trigger('mc-overlay-opened', href)
			}
		},
		hideOverlay: function(now) {
			$(document).trigger('mc-overlay-closing')
			var closeY = mc.oc.overlayWrap.height()

			mc.oc.overlay.empty()
			mc.oc.overlayWrap.removeClass('mc-status-active')
			$(document).trigger('mc-overlay-closed')
		},
		getLznString: function(key, replacements) {
			var strings = mc.lzn
			if (strings === undefined) {
				return key
			}

			key = typeof key !== 'undefined' ? key : ''

			var time = new Date().getTime() / 1000
			strings['datetime'] = Date.strftime(
				strings['datetime-format'],
				time
			)
			strings['date'] = Date.strftime(strings['date-format'], time)
			strings['time'] = Date.strftime(strings['time-format'], time)
			strings['year'] = Date.strftime('%Y', time)

			if (typeof replacements == 'object') {
				$.extend(true, strings, replacements)
			}

			var val = typeof strings[key] === 'undefined' ? key : strings[key]

			while (true) {
				var placeholders = val.match(/\[\[(.*?)\]\]/g)
				if (!placeholders || !placeholders.length) return val

				$.each(placeholders, function(i, v) {
					var lznKey = v.substr(2).substr(0, v.length - 4),
						replacement = strings[lznKey]
					if (typeof replacement === 'undefined') replacement = lznKey
					val = val.replace('[[' + lznKey + ']]', replacement)
				})
			}
		},
		mcajax: function(options) {
			options = $.extend(
				true,
				{
					ajax: {
						type: 'POST',
						dataType: 'json',
						url: '/api/',
					},
					ns: 'mc',
					scope: document,
					before: null,
					done: null,
					success: null,
					error: null,
					fail: null,
					always: null,
				},
				options
			)

			var namespace = options.ns
			if (namespace != '' && namespace.charAt(0) != '.')
				namespace = '.' + namespace

			if (typeof options.before === 'function') {
				var newOptions = options.before(options)
				if (newOptions === false) return
				if (typeof newOptions !== 'undefined') options = newOptions
			}

			$(options.scope).trigger('before' + namespace, [options])

			var xhr = $.ajax(options.ajax)
				.done(function(data) {
					if (typeof options.done === 'function')
						options.done(data, options, xhr, arguments)
					$(options.scope).trigger('done' + namespace, [options])
					if (data.success) {
						if (typeof options.success === 'function')
							options.success(data, options, xhr, arguments)
						$(options.scope).trigger('success' + namespace, [
							data,
							options,
							xhr,
							arguments,
						])
					} else {
						if (typeof options.error === 'function')
							options.error(data, options, xhr, arguments)
						$(options.scope).trigger('error' + namespace, [
							data,
							options,
							xhr,
							arguments,
						])
					}
				})
				.fail(function() {
					if (typeof options.fail === 'function')
						options.fail(options, xhr, arguments)
					$(options.scope).trigger('fail' + namespace, [
						options,
						xhr,
						arguments,
					])
				})
				.always(function(data, textStatus, jqXHR) {
					if (typeof options.always === 'function')
						options.always(data, options, xhr, arguments)
					$(options.scope).trigger('always' + namespace, [
						data,
						options,
						xhr,
						arguments,
					])
				})
		},
		addContact: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/addContact',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-addcontact',
					},
					options
				)
			)
		},
		addContactGroup: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/addContactGroup',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-addcontactgroup',
					},
					options
				)
			)
		},
		updateContactGroup: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/updateContactGroup',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-updatecontactgroup',
					},
					options
				)
			)
		},
		deleteContactGroups: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/deleteContactGroups',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-deletecontactgroups',
					},
					options
				)
			)
		},
		addContactsToGroups: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/addContactsToGroups',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-addcontactstogroups',
					},
					options
				)
			)
		},
		removeContactsFromGroups: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/removeContactsFromGroups',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-removecontactsfromgroups',
					},
					options
				)
			)
		},
		convertToTemplate: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/convertToTemplate',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-converttotemplate',
					},
					options
				)
			)
		},
		archive: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/archive',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
								archive: 'y',
							},
						},
						ns: 'mc-archive',
					},
					options
				)
			)
		},
		deleteAsset: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/deleteAsset',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-deleteasset',
					},
					options
				)
			)
		},
		deleteCampaign: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/deletecampaign',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-deletecampaign',
					},
					options
				)
			)
		},
		deleteTemplate: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/deletetemplate',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-deletetemplate',
					},
					options
				)
			)
		},
		deleteContacts: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/deleteContacts',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-deletecontacts',
					},
					options
				)
			)
		},
		fav: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/favorite',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
								favorite: 'y',
							},
						},
						ns: 'mc-favorite',
					},
					options
				)
			)
		},
		getContacts: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/getContacts',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-getcontacts',
					},
					options
				)
			)
		},
		getContactGroups: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/getContactGroups',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-getcontactgroups',
					},
					options
				)
			)
		},
		getRecipients: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/getRecipients',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-getrecipients',
					},
					options
				)
			)
		},
		updateAsset: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/updateAsset',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-updateasset',
					},
					options
				)
			)
		},
		updateCampaign: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/updateCampaign',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-updatecampaign',
					},
					options
				)
			)
		},
		updateDoc: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/updateDoc',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-updatedoc',
					},
					options
				)
			)
		},
		updateContact: function(options) {
			mc.fn.mcajax(
				$.extend(
					true,
					{
						ajax: {
							url: '/api/public/updateContact',
							data: {
								userKey: mc.user.userKey,
								accountKey: mc.account.accountKey,
							},
						},
						ns: 'mc-updatecontact',
					},
					options
				)
			)
		},
		prepareVideoPlayers: function() {
			$('.mc-video-element').each(function() {
				new MCVideoPlayer(this)
			})
		},
		navigateOnParent: function(url) {
			window.parent.postMessage({
				type: 'content-redirect',
				url: url,
			}, '*') // TODO: Instead of '*' we need to set the mc-ui domain.
		},
		notifyParent: function(type, data) {
			window.parent.postMessage({
				type: type,
				data: data,
			}, '*') // TODO: Instead of '*' we need to set the mc-ui domain.
		},
	},
	alerts: {
		config: {
			autoOpen: false,
			modal: true,
		},
		success: function(msg, options) {
			$.growl(
				$.extend(
					true,
					mc.alerts.config,
					{ message: msg, title: mc.fn.getLznString('Success!') },
					options
				)
			)
		},
		info: function(msg, options) {
			$.growl.notice(
				$.extend(
					true,
					mc.alerts.config,
					{ message: msg, title: mc.fn.getLznString('Alert!') },
					options
				)
			)
		},
		error: function(msg, options) {
			$.growl.error(
				$.extend(
					true,
					mc.alerts.config,
					{ message: msg, title: mc.fn.getLznString('Error!') },
					options
				)
			)
		},
		warn: function(msg, options) {
			$.growl.warning(
				$.extend(
					true,
					mc.alerts.config,
					{ message: msg, title: mc.fn.getLznString('Warning!') },
					options
				)
			)
		},
	},
	settings: {
		tiptip: {
			maxWidth: 'auto',
			edgeOffset: 5,
			defaultPosition: 'bottom',
			delay: 200,
		},
	},
	swfuploadConfig: {
		flash_url: '/assets/swfupload/swfupload.swf',
		upload_url: '/api/',
		file_post_name: 'assetFile',
		post_params: {
			apiMethod: 'addAsset',
			userKey: '',
			accountKey: '',
		},
		file_size_limit: '900 MB',
		file_types: '',
		file_types_description: 'Select Files',
		file_upload_limit: 0,
		file_queue_limit: 0,
		custom_settings: {
			progressTarget: 'upload-item-list',
			cancelButtonId: 'swfupload-cancel-all',
		},
		debug: false,
		button_image_url: '/assets/images/shim.png',
		button_width: '68',
		button_height: '26',
		button_placeholder_id: 'swfupload-button',
		button_text: '',
		button_text_style: '',
		button_text_left_padding: 0,
		button_text_top_padding: 0,
		button_window_mode: 'transparent',
		prevent_swf_caching: true,
		/*
        file_queued_handler : fileQueued,
        file_queue_error_handler : fileQueueError,
        file_dialog_complete_handler : fileDialogComplete,
        upload_start_handler : uploadStart,
        upload_progress_handler : uploadProgress,
        upload_error_handler : uploadError,
        upload_success_handler : uploadSuccess,
        queue_complete_handler : queueComplete, // Queue plugin event
        upload_complete_handler : uploadComplete
        */
	},
	validateConfig: {
		errorPlacement: function(error, element) {
			error.appendTo(element.closest('.mc-field').find('.mc-field-label'))
		},
		errorElement: 'span',
		errorClass: 'mc-input-error',
		rules: {},
	},
	qs: {
		delay: 150,
		stripeRows: ['qs-odd', 'qs-even'],
		show: function() {
			$(this)
				.removeClass('qs-hide')
				.addClass('qs-show')
				.show()
		},
		hide: function() {
			$(this)
				.removeClass('qs-show')
				.addClass('qs-hide')
				.hide()
		},
	},
	highcharts: {
		charts: {
			stock: {
				chart: {
					events: {
						redraw: function(e) {
							var extremes = {
								min:
									this.xAxis[0].getExtremes().userMin ||
									this.xAxis[0].getExtremes().dataMin,
								max:
									this.xAxis[0].getExtremes().userMax ||
									this.xAxis[0].getExtremes().dataMax,
							}
							var daterange =
								extremes.min == extremes.max
									? Highcharts.dateFormat(
											'%m/%d/%Y',
											extremes.min
									  )
									: Highcharts.dateFormat(
											'%m/%d/%Y',
											extremes.min
									  ) +
									  ' - ' +
									  Highcharts.dateFormat(
											'%m/%d/%Y',
											extremes.max
									  )
							$(this.container)
								.closest('.report-region')
								.find('.daterange-label')
								.text(daterange)
						},
					},
					type: 'spline',
					plotBackgroundColor: '#ffffff',
				},
				xAxis: {
					gridLineWidth: 1,
					type: 'datetime',
					minTickInterval: 86400000,
					tickmarkPlacement: 'on',
					lineColor: '#E2E3E3',
					lineWidth: 2,
				},
				yAxis: {
					offset: 5,
					min: 0,
					labels: {
						align: 'right',
						x: -3,
						y: 6,
					},
					gridLineDashStyle: 'longdash',
					title: {
						text: null,
					},
					minTickInterval: 1,
				},
				rangeSelector: {
					enabled: false,
				},
				plotOptions: {
					series: {
						turboThreshold: 10000,
						marker: {
							enabled: false,
							symbol: 'circle',
						},
					},
					area: {
						stacking: null,
						shadow: false,
					},
				},
				credits: {
					enabled: false,
				},
				navigator: {
					enabled: false,
				},
				exporting: {
					enabled: false,
				},
				scrollbar: {
					enabled: false,
				},
				title: {
					enabled: false,
				},
				tooltip: {
					backgroundColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
						},
						stops: [
							[0, '#FFF'],
							[1, '#EEE'],
						],
					},
					borderColor: 'gray',
					borderWidth: 1,
					shared: true,
					crosshairs: true,
				},
				legend: {
					enabled: true,
				},
			},
			pie: {
				//PIE CHART CONFIG
				title: {
					text: null,
				},
				legend: {
					reversed: true,
					labelFormatter: function() {
						var row = $(this.legendGroup.element)
							.closest('.mc-region-body')
							.find(
								'.mc-charttable-table tbody tr:eq(' +
									this.x +
									')'
							)
						row.html(
							'<td><span class="square" style="background-color:' +
								this.color +
								';"></span></td>' +
								'<td>' +
								this.name +
								'</td>' +
								'<td>' +
								Highcharts.numberFormat(this.y, 0) +
								'</td>' +
								'<td>' +
								Highcharts.numberFormat(this.percentage, 2) +
								'%</td>'
						)
					},
					userHTML: true,
				},
				plotOptions: {
					pie: {
						innerSize: '40%',
					},
				},
			},

			column: {
				//COLUMN CHART CONFIG
				title: {
					text: null,
				},
				legend: {
					enabled: true,
					borderWidth: 0,
					verticalAlign: 'bottom',
				},
				tooltip: {
					formatter: function() {
						return (
							this.series.name +
							'<br>' +
							this.x +
							': <strong>' +
							Highcharts.numberFormat(this.y, 0) +
							'</strong>'
						)
					},
				},
				plotOptions: {
					column: {
						pointPadding: 0.1,
						groupPadding: 0.2,
						borderWidth: 0,
					},
				},
			},

			areaspline: {
				//AREASPLINE CHART CONFIG
				chart: {
					zoomType: 'x',
				},
				title: {
					text: null,
				},
				legend: {
					enabled: true,
					borderWidth: 0,
					verticalAlign: 'bottom',
				},
				yAxis: {
					title: {
						text: null,
					},
					maxPadding: 0.1,
					gridLineColor: '#EBEBEB',
				},
			},

			bar: {
				//BAR CHART CONFIG
				chart: {
					defaultSeriesType: 'bar',
				},
				title: {
					text: null,
					enabled: false,
				},
				yAxis: {
					title: {
						text: null,
					},
				},
				legend: {
					reversed: true,
					enabled: true,
				},
				tooltip: {
					backgroundColor: {
						linearGradient: {
							x1: 0,
							y1: 0,
							x2: 0,
							y2: 1,
						},
						stops: [
							[0, 'white'],
							[1, '#EEE'],
						],
					},
					borderColor: 'gray',
					borderWidth: 1,
					formatter: function() {
						return (
							this.series.name +
							': ' +
							Highcharts.numberFormat(this.y, 0) +
							' (' +
							Highcharts.numberFormat(this.percentage, 2) +
							'%)'
						)
					},
				},
				plotOptions: {
					series: {
						stacking: 'normal',
					},
				},
			},
		},
	},
}

$(document).ready(function() {
	mc.fn.init()
})
